import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as $ from 'jquery';
/**
 * @ignore
 */
@Injectable({
  providedIn: 'root',
})
export class UnreadMessagesService implements OnInit {
  /**
   * @ignore
   */
  _count$ = new BehaviorSubject(0);

  /**
   * @ignore
   */
  chatBlinkTimer: any = '';

  /**
   * @ignore
   */
  getCount(): Observable<any> {
    return this._count$.asObservable();
  }

  /**
   * @ignore
   */
  get count() {
    return this._count$.getValue();
  }

  /**
   * @ignore
   */
  increment() {
    this._count$.next(this._count$.getValue() + 1);
  }

  /**
   * @ignore
   */
  clearCount() {
    this._count$.next(0);
  }

  /**
   * @ignore
   */
  toggleFunc() {
    if (this.chatBlinkTimer !== '')
      if ($('#c2e_button_chat').hasClass('chatBlinkOn')) {
        $('#c2e_button_chat').removeClass('chatBlinkOn');
        $('#c2e_button_chat').addClass('chatBlinkOff');
      } else {
        $('#c2e_button_chat').addClass('chatBlinkOn');
        $('#c2e_button_chat').removeClass('chatBlinkOff');
      }
  }

  /**
   * @ignore
   */
  stopTimerJob() {
    $('#c2e_button_chat').removeClass('chatBlinkOn');
    $('#c2e_button_chat').removeClass('chatBlinkOff');
    clearInterval(this.chatBlinkTimer);
    this.chatBlinkTimer = '';
  }

  /**
   * @ignore
   */
  constructor() {
    window.addEventListener('ChatClosed', () => {
      this.clearCount();
    });
  }
  /**
   * @ignore
   */
  ngOnInit() {}
}
