import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Pipe({
  name: 'safeAsync',
})
export class SafeAsyncPipe implements PipeTransform {
  transform(source: Observable<any>): any {
    return source.pipe(
      map((data) => ({ data, error: null })),
      catchError((error) => {
        return [{ data: null, error }];
      })
    );
  }
}
