import { createAction, props } from '@ngrx/store';
import { ContactUsData, GenesysEwtVqData } from '../../contact/contact-us-data.component';

/**
 * Called by {@link ../injectables/ContactEffects.html#setContactUs$|setContactUs$} effect after setting Contact ID to get authorization token
 */
export const acquireAuthorizationToken = createAction('[Contact] Acquire Authorization Token');

/**
 * Called by {@link ../injectables/ContactEffects.html#getAuthToken$|getAuthToken$} effect after sucessfull getting authentication token
 */
export const setAuthorizationToken = createAction('[Contact] Set Authorization Token', props<{ token: string }>());

/**
 * Sets Contact ID for OKA Article
 */
export const setContactUsId = createAction('[Contact] Set Contact Us ID', props<{ contactUsId: string }>());

/**
 * Called by {@link ../injectables/ContactService.html#getAnswer|ContactService.getAnswer} to set contactUsError value
 */
export const loadContactUsFailure = createAction('[Contact] Load Contact Us Text Failure', props<{ error: any }>());

export const clearContactUsError = createAction('[Contact] Clear Contact Us Text Error');

/**
 * Contact page is eligible for Callback
 */
export const callbackEligible = createAction('[Contact] Callback Eligible');

/**
 * Contact page is ineligible for Callback
 */
export const callbackIneligible = createAction('[Contact] Callback Ineligible');

/**
 * Set Active Callback to true and set Callback phone number
 */
export const activeCallbackIsTrue = createAction(
  '[Contact] Active Callback Request',
  props<{ callbackNumber: string }>()
);

/**
 * Sets Active Callback to false
 */
export const activeCallbackIsFalse = createAction('[Contact] No Active Callback Request');

/**
 * User clicks on 'Request a Call' button
 */
export const initiateCallbackForm = createAction('[Contact] Initiate Callback Request Form');

/**
 * Displays Callback form
 */
export const showCallbackForm = createAction('[Contact] Show Callback Request Form');

/**
 * Hides Callback form
 */
export const hideCallbackForm = createAction('[Contact] Hide Callback Request Form');

/**
 * Sets error message when Callback is requested
 */
export const setCallbackRequestError = createAction('[Contact] Callback Request Error', props<{ error: string }>());

/**
 * Sets view to display after Callback request is submitted
 */
export const setCallbackScreen = createAction('[Contact] Set Callback Post-Form Screen', props<{ screen: string }>());

/**
 * Sets Genesys Callback ID
 */
export const setCallbackId = createAction('[Contact] Set Callback ID', props<{ id: string }>());

/**
 * Clears Genesys Callback ID
 */
export const clearCallbackId = createAction('[Contact] Clear Callback ID');

/**
 * Sets Estimated Wait Time
 */
export const setEWT = createAction('[Contact] Set EWT', props<{ ewt: number }>());

/**
 * Clears Estimated Wait Time
 */
export const clearEWT = createAction('[Contact] Clear EWT');

/**
 * Fires when Callback can still be cancelled
 */
export const callbackIsCancellable = createAction('[Contact] Callback Can Be Cancelled');

/**
 * Fires when Callback can no longer be cancelled
 */
export const callbackIsNotCancellable = createAction('[Contact] Callback Can Not Be Cancelled');

/**
 * Sets Contact tab view to display
 */
export const setContactVisibleComponent = createAction(
  '[Contact] Set Visible Component',
  props<{ component: string }>()
);

/**
 * Sets callback VQ
 */
export const setCallbackVQ = createAction('[Contact] Set Callback Virtual Queue', props<{ vq: string }>());

export const setContactUsArray = createAction(
  '[Contact] Set Contact Us Row Array',
  props<{ array: Array<ContactUsData> }>()
);

export const setContactUsObject = createAction('[Contact] Set Contact Us Row Object', props<{ object: any }>());

export const setCallbackEwtArray = createAction('[Contact] Set EWT Array', props<{ array: Array<GenesysEwtVqData> }>());

export const setCallbackEwtObject = createAction('[Contact] Create Object with EWT Data', props<{ object: any }>());

export const triggerEwtEffect = createAction('[Contact] Trigger EWT Effect', props<{ object: any }>());

export const startEwtPoll = createAction('[Contact] Start EWT Polling');

export const setPolicyNumber = createAction('[Contact] Set Policy Number', props<{ policyNumber: any }>());

export const setDirectToCallbackTrue = createAction('[Contact] Set direct to callback to True');

export const setDirectToCallbackFalse = createAction('[Contact] Set direct to callback to False');

export const acquirePriority = createAction('[Contact] Get Priority from scoring model');

export const setPriority = createAction('[Contact] Set Priority', props<{ priority: number }>());