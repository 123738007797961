import { Injectable, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { UnreadMessagesService } from './unread-msgs.service';
import * as kvpMap from './kvpMap';
import { Store } from '@ngrx/store';
import { getEWStatus, State, ewOpenOnChat, getActiveTab } from '../store/reducers';
import { changeTabsNoPost, ewClose, ewOpen, setContactUsURL, setFAQsURL } from '../store/actions/tabs.actions';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil, take, catchError } from 'rxjs/operators';
import { autosubmitProactiveChatTrue, clearUnreadMessages, doNotLoadDataIsFalse, isNotLoading } from '../store/actions/chat.actions';
import { getDoNotLoadStatus, getUnreadMessageCount, getActiveChatStatus } from '../store/selectors/chat.selectors';
import { getCallbackEligibilityState, getCallbackState } from '../store/selectors/contact.selectors';
import * as moment from 'moment-timezone';
import {
  callbackEligible,
  callbackIneligible,
  setContactUsId,
  activeCallbackIsTrue,
  setCallbackRequestError,
  setCallbackScreen,
  activeCallbackIsFalse,
  setCallbackId,
  callbackIsCancellable,
  callbackIsNotCancellable,
  hideCallbackForm,
  setEWT,
  setCallbackVQ,
  initiateCallbackForm,
  setDirectToCallbackTrue,
  acquirePriority
} from '../store/actions/contact.actions';
import { changeTabs } from '../store/actions/tabs.actions';
import { FormField } from '../chat/chat-form/chat-form.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { options } from 'less';

/**
 * Handles messaging to/from application and within application
 *
 * @export
 * @class MessagingHandlerService
 * @implements {OnDestroy}
 */
@Injectable({
  providedIn: 'root',
})
export class MessagingHandlerService implements OnDestroy {
  /**
   * Used to unsubscribe from subscriptions on ngDestroy
   *
   * @type {Subject<boolean>}
   */
  unsubscribe$: Subject<boolean> = new Subject();

  /**
   * Used to dynamically add Chat form fields
   */
  formFieldData = new BehaviorSubject({});

  /**
   *Creates an instance of MessagingHandlerService.
   * @param {UnreadMessagesService} unreadMsgService
   * @param {ContactService} contactService
   * @param {Store<State>} store
   */
  constructor(
    private unreadMsgService: UnreadMessagesService,
    private http: HttpClient,
    private cookie: CookieService,
    private store: Store<State>
  ) {
    window.addEventListener('message', this.receiveMessage.bind(this), false);
   
    this.activeTab$ = this.store.select(getActiveTab);
    this.activeTab$.pipe(takeUntil(this.unsubscribe$)).subscribe((tab) => {
      this.tabName = tab;
    });
    this.ewOpen$ = this.store.select(getEWStatus);
    this.ewOpen$.pipe(takeUntil(this.unsubscribe$)).subscribe((status) => {
      this.ewOpenStatus = status;
    });
    this.ewOpenOnChat$ = this.store.select(ewOpenOnChat);
    this.ewOpenOnChat$.pipe(takeUntil(this.unsubscribe$)).subscribe((status) => {
      this.shouldClearMsgCount = status;
    });
    this.unreadMsgCount$ = this.store.select(getUnreadMessageCount);
    this.unreadMsgCount$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.messageCount = data;
    });
    this.doNotLoadData$ = this.store.select(getDoNotLoadStatus);
    this.doNotLoadData$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.doNotLoadDataBool = data;
    });
    this.activeChat$ = this.store.select(getActiveChatStatus);
    this.activeChat$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.activeChat = data;
    });
    this.activeCallback$ = this.store.select(getCallbackState);
    this.activeCallback$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.activeCallback = data;
    });
    this.callbackEligibility$ = this.store.select(getCallbackEligibilityState);
    this.callbackEligibility$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((eligibility) => (this.callbackEligibility = eligibility));
  }

  /**
   * NOT IN CURRENT USE: Host State functionality
   *
   * @type {HostState}
   */
  hostState: HostState = new HostState();

  /**
   * Holds event data for {@link #receiveMessage|receiveMessage}
   */
  eventData;

  /**
   * Map of Oracle to Genesys KVPs
   *
   * @type {Object}
   */
  kvp: Object = kvpMap.KVP_MAP;

  /**
   * NOT IN CURRENT USE: Host State functionality
   */
  _messageType;

  /**
   * VALIDATE USAGE: never seems to get set to true
   */
  doNotLoadData$;

  /**
   * VALIDATE USAGE: never seems to get set to true
   */
  doNotLoadDataBool;

  /**
   * EW Open/Closed Status (subscribed value of ewOpen$)
   */
  ewOpenStatus;

  /**
   * Active Tab (subscribed value of activeTab$)
   */
  tabName;

  /**
   * Active Tab (observable)
   */
  activeTab$;

  /**
   * EW Open/Closed Status (observable)
   */
  ewOpen$;

  /**
   * NOT IN CURRENT USE: Unread Messages functionality
   */
  ewOpenOnChat$;

  /**
   * NOT IN CURRENT USE: Unread Messages functionality
   */
  unreadMsgCount$;

  /**
   * NOT IN CURRENT USE: Unread Messages functionality
   */
  messageCount;

  /**
   * NOT IN CURRENT USE: Unread Messages functionality
   */
  shouldClearMsgCount;

  /**
   *  DEPRECATED: No longer in use
   */
  contactUsURL;

  /**
   * OKA API Article ID
   */
  contactUsId;

  /**
   * Oracle URL to PHP page
   */
  faqURL;

  /**
   * Active Chat Status (observable)
   */
  activeChat$;

  /**
   * Active Chat Status (subscribed value of activeChat$)
   */
  activeChat;

  /**
   * Active Callback Status (observable)
   */
  activeCallback$;

  /**
   * Active Callback Status (subscribed value of activeCallback$)
   */
  activeCallback;

  /**
   * CHECK IF USED
   */
  genesysObject = {};

  /**
   * Triggers patching of form and acquisition of browser version
   */
  shouldLoadData$ = new BehaviorSubject<boolean>(false);

  /**
   * Holds Medallia survey codes
   */
  surveyParamsNS = [];

  /**
   * Holds Medallia survey session attributes like participant id and headers
   */
  sessionParams: { [key: string]: any };

  /**
   * Contact page eligibility for Callback
   * @type {Observable<boolean>}
   */
  callbackEligibility$: Observable<boolean>;

  /**
   * Contact page eligibility for Callback (subscribed value)
   * @type {boolean}
   */
  callbackEligibility: boolean;

  /**
   * Unsubscribes from various streams
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  /**
   * Fires when 'message' event is detected
   *
   * Used for both state and data management
   *
   * @param event
   * @returns
   */
  receiveMessage: any = (event: MessageEvent) => {
    this.eventData = event.data;

    switch (this.eventData.name) {
      case 'hostStateMessage':
        this.processHostStateMessage(event);
        break;
      case 'uiStateMessage':
        this.uiStateMessage(event);
        break;
      case 'ewMinimized':
        this.store.dispatch(ewClose());
        parent.postMessage({ name: 'genUnreadMsg', messageCount: this.messageCount }, '*');
        break;
      case 'ewMaximized':
        sessionStorage['ewMaximizedTriggered'] = true;
        $('#endChatAlert').addClass('hidden');
        this.store.dispatch(ewOpen());
        this.store.dispatch(isNotLoading());
        if (this.shouldClearMsgCount === true) {
          this.store.dispatch(clearUnreadMessages());
          this.unreadMsgService.stopTimerJob();
        }
        break;
      case 'ewCloseChatMessage':
        // If activetab is not chat, set to chat
        if (this.tabName !== 'chat') {
          this.store.dispatch(changeTabsNoPost({ id: 'chat' }));
          $('.cx-window-manager').show();
          if (this.unreadMsgService.count > 0) {
            this.store.dispatch(clearUnreadMessages());
            this.unreadMsgService.stopTimerJob();
          }
        }
        $('.cx-close').click();
        if (this.activeChat === true) {
          const EVENT = new CustomEvent('WebChatClosedAndMinimized');
          sessionStorage['activeChat'] = 'false';
          window.dispatchEvent(EVENT);
        }
        break;
      case 'eeData':
        this.processEEDataMessage(event);
        break;
      case 'cbData':
        this.processCallbackDataMessage(event);
        break;
      case 'addNewFormField':
        this.processaddNewFormFieldMessage(event);
        break;
      case 'cbStatus':
        this.processCallbackStatusMessage(event);
        break;
      case 'contactUsURL':
        this.processContactUsURLMessage(event);
        break;
      case 'contactUsId':
        this.processContactUsIdMessage(event);
        break;
      case 'faqURL':
        this.processFAQUrlMessage(event);
        break;
      case 'medalliaFormId':
        window.dispatchEvent(new CustomEvent('validMedalliaExists'));
        // console.log(event);
        if (event.data.surveyParams && event.data.surveyParams.length > 0) {
          for (let i = 0; i < event.data.surveyParams.length; i++) {
            this.surveyParamsNS.push(event.data.surveyParams[i]);
          }
        }

        break;
      case 'ExitChatWindow':
        window.dispatchEvent(new CustomEvent('ExitChatWindow'));
        break;
      case 'autosubmitProactiveChat':
        sessionStorage['ewMaximizedTriggered'] = false;
        this.store.dispatch(autosubmitProactiveChatTrue());  
        break;
      case 'loadCallbackForm':
        sessionStorage['ewMaximizedTriggered'] = false;
         // navigate to contact us tab and prevent the chat form from loading
         this.store.dispatch(changeTabs({ id: 'contact' }));
         // trigger ewOpen (including passing message to parent to maximize EW)
         this.store.dispatch(ewOpen());
         this.store.dispatch(setCallbackVQ({ vq: event.data.vq }));
         this.store.dispatch(initiateCallbackForm()); 
         // this will set directToCallback to true which will be used for auto submit when we switch the tab back to chat 
         this.store.dispatch(setDirectToCallbackTrue());  
         break;
      case 'getCallbackPriority':
        sessionStorage['scoringModelPayload'] = JSON.stringify(event.data.param.scoringModelPayload);
        sessionStorage['token'] = event.data.param.token;
        sessionStorage['qcn'] = event.data.param.qcn;
        sessionStorage['guid'] = event.data.param.guid;
        this.store.dispatch(acquirePriority());
        break;
      case 'setPrioritySecrets':
        sessionStorage['priorityClientId'] = event.data.param.id;
        sessionStorage['priorityClientSecret'] = event.data.param.secret;
        break;
      default:
        break;
    }

    return event;
  };

  setSessionAttributes() {
    if (this.activeChat) {
      this.sessionParams = {};
      this.sessionParams['sessionId'] = this.cookie.get('_genesys.widgets.webchat.state.pureengage-v3-rest.session');
      const apiKey = '6e17d0db-332a-4897-94ab-8e51eec114d5';
      const nexusKey = this.cookie.get('_genesys.widgets.webchat.state.pureengage-v3-rest.keys');
      this.sessionParams['header'] = new HttpHeaders({ 'x-api-key': apiKey, 'x-nexus-client-key': nexusKey });
    }
  }

  getParticipantId() {
    const participantIdList = [];
    return this.http
      .get(
        `https://api-hartford.digital.genesyscloud.com/nexus/v3/chat/sessions/${this.sessionParams['sessionId']}/participants`,
        { headers: this.sessionParams['header'] }
      )
      .pipe(
        take(1),
        map((response) => {
          if (response['status']['code'] == 'SUCCESS') {
            response['data']['entities'].forEach(function (participant) {
              participantIdList.push(participant.id);
            });
          }
          return participantIdList;
        }),
        catchError((e) => {
          console.log(e);
          return participantIdList;
        })
      );
  }

  getAgentName(participantId: any) {
    this.http
      .get(
        `https://api-hartford.digital.genesyscloud.com/nexus/v3/chat/sessions/${this.sessionParams['sessionId']}/participants/${participantId}`,
        { headers: this.sessionParams['header'] }
      )
      .subscribe((response) => {
        if (response['status']['code'] == 'SUCCESS') {
          if (response['data']['clientType'] == 'Agent' && sessionStorage['agent_firstname'] == null) {
            this.setAgentName(response);
          }
          //we will set the session storage from extension.js when a new agent is connected. And we need the latest agent info to be passed
          else if(response['data']['clientType'] == 'Agent' && sessionStorage['agent_firstname'] ==  response['data']['firstName']) {
            this.setAgentName(response);
          }
        }
      });
  }

  setAgentName(response: any) {
    sessionStorage['agent_firstname'] = response['data']['firstName'];
    sessionStorage['agent_lastname'] = response['data']['lastName'];
    this.surveyParamsNS['agent_firstname'] = response['data']['firstName'];
    this.surveyParamsNS['agent_lastname'] = response['data']['lastName'];
  }

  postMedalliaSurvey() {
    this.surveyParamsNS['chat_interactionId'] = sessionStorage.getItem('chat_interactionId');
    const messageObject = { name: 'fireMedalliaSurvey', params: {} };
    if (Object.keys(this.surveyParamsNS).length > 0) {
      Object.keys(this.surveyParamsNS).forEach((param) =>{
        switch (param) {
          case 'dateTime':
            const timestamp = Date.now();
            messageObject.params['dateTime'] = moment
              .tz(timestamp, 'America/New_York')
              .format('MM/DD/YYYY hh:mm:ss A z');
            break;
          case 'page':
            messageObject.params['page'] = sessionStorage['CHAT_pageTitle'];
            break;
          default:
            messageObject.params[param] = sessionStorage[param];
            break;
        }
      });
    }
    parent.postMessage(messageObject, '*');
  }

  initiatSkillNames(skillNameIndex, message)
  {
      switch(skillNameIndex) {
        case '100' :
          sessionStorage.setItem('BI_SC_Web_Questions', message.data.param[skillNameIndex]);
        break;
        case '101' :
          sessionStorage.setItem('BI_SC_Policy_Billing', message.data.param[skillNameIndex]);
        break;
        case '102' :
          sessionStorage.setItem('BI_SC_COI', message.data.param[skillNameIndex]);
        break;
        case '103' :
          sessionStorage.setItem('BI_SC_Audit', message.data.param[skillNameIndex]);
        break;
        case '104' :
          sessionStorage.setItem('Technical_Support_-_AIS', message.data.param[skillNameIndex]);
        break;
        case '105' :
          sessionStorage.setItem('PL_Agency_Service_-_Home', message.data.param[skillNameIndex]);
        break;
        case '106' :
          sessionStorage.setItem('BI_Service', message.data.param[skillNameIndex]);
        break;
        case '107' :
          sessionStorage.setItem('BI_Audit', message.data.param[skillNameIndex]);
        break;
        case '108' :
          sessionStorage.setItem('Download_AMS_Support_-_AIS', message.data.param[skillNameIndex]);
        break;
        case '109' :
          sessionStorage.setItem('PL_Service', message.data.param[skillNameIndex]);
        break;
        case '110' :
          sessionStorage.setItem('PL_Sales_-_Auto', message.data.param[skillNameIndex]);
        break;
        case '111' :
          sessionStorage.setItem('PL_Sales_-_Home', message.data.param[skillNameIndex]);
        break;
        case '112' :
          sessionStorage.setItem('SC_Direct_Sales', message.data.param[skillNameIndex]);
        break;
    }
  }

  /**
   * Processes messages with shape:
   *
   * ``{name: 'eeData', param: {key: value}}``
   *
   * @type {*}
   */
  public processEEDataMessage: any = (message: any) => {
    //console.log(message);
    const _eeMessageType = Object.keys(message.data.param)[0];
    const t = this.kvp[_eeMessageType];
    this.initiatSkillNames(_eeMessageType, message);
    if (t != undefined) {
      const trimmedVal = message.data.param[_eeMessageType].replace(/(\r\n|\n|\r)/gm, '|').trim();
      const event = new CustomEvent('AddUserData', { detail: { [t]: trimmedVal } });
      this.genesysObject[t] = trimmedVal;
      if (message.data.is_c2v) {
        sessionStorage['subject'] = message.data.data.subject;
        sessionStorage['firstname'] = message.data.data.firstName;
        sessionStorage['lastname'] = message.data.data.lastName;
        sessionStorage['email'] = message.data.data.emailAddress;

        $('#subject').val(message.data.data.subject);
        $('#subject').blur();
        $('#firstName').val(message.data.data.firstname);
        $('#lastName').val(message.data.data.lastname);
        $('#email').val(message.data.data.email);

        const v = new CustomEvent('ValidChatFormSubmitted');
        window.dispatchEvent(v);
      }

      window.dispatchEvent(event);
    }
    if (_eeMessageType === 'done') {
      if (!this.doNotLoadDataBool) {
        this.loadDataIsTrue();
        this.store.dispatch(doNotLoadDataIsFalse());
      }
    }
  };

  /**
   * Adds additional form fields to Chat submission form
   * */
  public processaddNewFormFieldMessage: any = (message: any) => {
    if (message.data.hasOwnProperty('fieldValue')) {
      message.data.fieldValue = message.data.fieldValue;
    } else {
      message.data.fieldValue = '';
    }
    if (message.data.hasOwnProperty('fieldRequired')) {
      message.data.fieldRequired = message.data.fieldRequired;
      if (message.data.fieldRequired == 'true' || message.data.fieldRequired == true) {
        message.data.fieldRequired = true;
      } else {
        message.data.fieldRequired = false;
      }
    } else {
      message.data.fieldRequired = false;
    }
    this.formFieldData.next(
      new FormField(
        message.data.fieldId,
        message.data.fieldLabel,
        message.data.fieldValue,
        message.data.fieldName,
        message.data.fieldRequired
      )
    );
  };

  /**
   * Processes extra data parameters for Callback from ew-hosted c2e_sendCallbackParams functions
   *
   * @param message
   */
  public processCallbackDataMessage: any = (message: any) => {
    const field = Object.keys(message.data.param)[0];
    if (field !== undefined && message.data.param[field] !== undefined) {
      const trimmedVal = message.data.param[field].replace(/(\r\n|\n|\r)/gm, '|').trim();
      sessionStorage[field] = trimmedVal;

      // console.log(`Callback variable set as `, sessionStorage[field]);
    } else {
      console.error('Invalid Callback Parameter');
    }
  };

  /**
   * Processes messages related to callback status of shape:
   *
   * ``{ name: 'cbStatus', status: statusMessage, errorType: ''}``
   *
   * Responsible for setting Callback screen amongst other Callback-related UI effects
   *
   * See cb_extension.js
   * @type {*}
   */
  public processCallbackStatusMessage: any = (message: MessageEvent) => {
    // console.log('cbstatus received');
    if (message.data.status === 'SUCCESS') {
      const statusType = message.data.statusType;
      // console.log(`Statustype is ${statusType}`);
      switch (statusType) {
        case 'findingAgent':
          this.store.dispatch(setCallbackScreen({ screen: 'findingAgent' }));
          break;
        case 'connected':
          this.store.dispatch(setCallbackScreen({ screen: 'connected' }));
          break;
        case 'cannotConnect':
          this.store.dispatch(setCallbackScreen({ screen: 'cannotConnect' }));
          this.store.dispatch(activeCallbackIsFalse());
          break;
        case 'cancelled':
          this.store.dispatch(setCallbackScreen({ screen: 'cancelled' }));
          this.store.dispatch(activeCallbackIsFalse());
          break;
        case 'EWT':
          this.store.dispatch(setEWT({ ewt: message.data.ewt }));
          break;
        default:
          break;
      }
    } else if (message.data.status === 'CONNECTING') {
      const statusType = message.data.statusType;
      switch (statusType) {
        case 'thankYou':
          this.store.dispatch(activeCallbackIsTrue({ callbackNumber: message.data.cbNumber }));
          this.store.dispatch(setCallbackScreen({ screen: 'thankYou' }));
          this.store.dispatch(setCallbackId({ id: message.data.id }));
          setTimeout(() => {
            {
              if (this.activeCallback) this.store.dispatch(setCallbackScreen({ screen: 'findingAgent' }));
            }
          }, 5000);
          break;
        case 'connectingCancellable':
          this.store.dispatch(callbackIsCancellable());
          break;
        case 'connectingNonCancellable':
          this.store.dispatch(callbackIsNotCancellable());
          break;
      }
    } else if (message.data.status === 'CANCELLED') {
      this.store.dispatch(setCallbackScreen({ screen: 'cancelled' }));
      this.store.dispatch(activeCallbackIsFalse());
      this.store.dispatch(callbackIsCancellable());
      this.store.dispatch(setCallbackId({ id: '' }));
    } else if (message.data.status === 'ERROR') {
      // console.log('Error');
      this.store.dispatch(setCallbackRequestError({ error: message.data.errorType }));
      this.store.dispatch(activeCallbackIsFalse());
      this.store.dispatch(hideCallbackForm());
      this.store.dispatch(setCallbackScreen({ screen: 'cannotConnect' }));
    } else if (message.data.status === 'ERROR-EWT') {
      this.store.dispatch(setEWT({ ewt: -1 }));
      this.store.dispatch(hideCallbackForm());
    } else if (message.data.status === 'ERROR-CANCEL') {
      this.store.dispatch(activeCallbackIsFalse());
      this.store.dispatch(callbackIsNotCancellable());
    } else {
      console.log('Uncaught CB Message');
      // console.log(message.data);
    }
  };

  /**
   * DEPRECATED: No longer in use
   *
   * @type {*}
   */
  public processContactUsURLMessage: any = (message: MessageEvent) => {
    if (this.contactUsURL !== message.data.data.url) {
      this.contactUsURL = message.data.data.url;
      this.store.dispatch(setContactUsURL({ url: message.data.data.url }));
    }
  };

  /**
   * Processes messages with shape:
   *
   * ``{ name: 'contactUsId', data: { id: contactUsID, cbDisplay: cbDisplay, cbQueue: cbQueue } }``
   *
   * - contactUsId: OKA Article ID
   *
   * - cbDisplay: Whether/not 'Request a Call' button should display
   *
   * - cbQueue: Queue to use for EWT and Routing. Matches ``c2e_cb_valueMap`` queue values in cb_extension.js
   *
   * @param message
   */
  public processContactUsIdMessage: any = (message: MessageEvent) => {
    if (this.contactUsId !== message.data.data.id) {
      // console.log('id received');
      // console.log(message.data);
      this.contactUsId = message.data.data.id;
      this.store.dispatch(setContactUsId({ contactUsId: message.data.data.id }));
    }
  };

  /**
   * Processes messages with shape:
   *
   * ``{name: 'faqURL', param: {url: value}}``
   *
   * @param message
   */
  public processFAQUrlMessage: any = (message: MessageEvent) => {
    if (this.faqURL !== message.data.data.url) {
      this.faqURL = message.data.data.url;
      this.store.dispatch(setFAQsURL({ url: message.data.data.url }));
    }
  };

  /**
   * Triggered when eeData Params returns done
   *
   * Triggers {@link ../components/ChatFormComponent.html#loadData|ChatFormComponent.loadData} to patch form and get browser version
   */
  loadDataIsTrue = () => {
    this.shouldLoadData$.next(true);
  };

  /**
   * NOT IN CURRENT USE: Host State functionality
   *
   * @type {*}
   */
  public processHostStateMessage: any = (message: any) => {
    this._messageType = Object.keys(message.data)[1];
    switch (this._messageType) {
      case 'page':
      case 'product':
      case 'category':
      case 'domain':
        this.hostState[this._messageType] = message.data[this._messageType];
        $(`input[name="${this._messageType}"]`).val(message.data[this._messageType]);
        this.hostState.hostStateData[this._messageType] = message.data[this._messageType];
        sessionStorage[this._messageType] = this.hostState[this._messageType];
        sessionStorage.hostStateData = JSON.stringify(this.hostState.hostStateData);
        break;
      case 'initializeState':
        this.hostState = message.data[this._messageType];
        break;
      default:
        return new Error();
    }
  };

  /**
   * DEPRECATED?: Validate not used
   *
   * @param message
   * @returns
   */
  public uiStateMessage: any = (message: any) => {
    return true;
  };
}

/**
 * NOT IN CURRENT USE: Host State functionality
 *
 * @export
 * @class HostState
 */
export class HostState {
  /**
   * NOT IN CURRENT USE: Host State functionality
   *
   * @private
   */
  private _page = '';

  /**
   * NOT IN CURRENT USE: Host State functionality
   *
   * @private
   */
  private _product = 0;

  /**
   * NOT IN CURRENT USE: Host State functionality
   *
   * @private
   */
  private _category = 0;

  /**
   * NOT IN CURRENT USE: Host State functionality
   *
   * @private
   */
  private _domain = '';

  /**
   * NOT IN CURRENT USE: Host State functionality
   *
   * @private
   */
  private _hostStateData: object = {
    page: this._page,
    product: this._product,
    category: this._category,
    domain: this._domain,
  };

  public get hostStateData() {
    return this._hostStateData;
  }
  public set hostStateData(value) {
    this._hostStateData = value;
  }

  public get page(): string {
    return this._page;
  }

  public set page(page: string) {
    this._page = page;
  }

  public get product(): number {
    return this._product;
  }

  public set product(product: number) {
    this._product = product;
  }

  public get category(): number {
    return this._category;
  }

  public set category(category: number) {
    this._category = category;
  }

  public get domain(): string {
    return this._domain;
  }

  public set domain(domain: string) {
    this._domain = domain;
  }
}
