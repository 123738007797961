<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div *ngFor="let question of questions" class="form-row">
      <app-question [question]="question" [form]="form"></app-question>
    </div>
    <div class="c2e-form-fields">
      <input
        [disabled]="form.invalid"
        type="button"
        name="submit"
        id="c2e-submit"
        class="btn btn-secondary btn-lg btn-block"
        value="Submit"
        disabled="true"
        (click)="onSubmit()"
        tabindex="99"
      />
      <input
        type="button"
        name="exit"
        id="c2e-cancel"
        class="btn btn-link"
        (click)="cancel()"
        value="Cancel"
        tabindex="100"
      />
    </div>
  </form>
</div>
