declare const window: any;

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store/reducers';
import * as fromActions from '../store/actions/contact.actions';
import * as $ from 'jquery';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContactService } from './contact.service';
import { QuestionService } from './callbackform/cbform-questions.service';
import { QuestionBase } from './callbackform/cbform-questions-base';

/**
 * Callback component
 *
 * @export
 * @class ContactCallbackComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-contact-callback',
  templateUrl: './contact-callback.component.html',
  styleUrls: ['./contact-callback.component.scss'],
  providers: [QuestionService],
})
export class ContactCallbackComponent implements OnInit, OnDestroy {
  questions$: Observable<QuestionBase<any>[]>;

  /**
   *Creates an instance of ContactCallbackComponent.
   * @param {Store<State>} store
   * @param {FormBuilder} fb
   * @param {ContactService} contactService
   */
  constructor(
    private store: Store<State>,
    private fb: FormBuilder,
    private contactService: ContactService,
    private questionsService: QuestionService
  ) {
    this.questions$ = questionsService.getQuestions();
  }

  /**
   * Used to unsubscribe from subscriptions on ngDestroy
   * @type {Subject<boolean>}
   */
  unsubscribe$: Subject<boolean> = new Subject();

  /**
   * Callback request form FormGroup
   *
   * @type {FormGroup}
   */
  requestForm: FormGroup;

  /**
   * DEPRECATED?: Validate
   */
  svg;

  formattedEwt$: Observable<string>;
  ngOnInit(): void {
    this.contactService.ewt$.pipe(takeUntil(this.unsubscribe$)).subscribe((ewt) => {
      this.formattedEwt$ = of(this.formatEwt(ewt));
    });
  }

  formatEwt = (ewt: number): string => {
    const threshold = 10;
    const ewtMinutes = Math.floor(ewt / 60);
    if (ewtMinutes >= threshold) {
      return `Greater than ${threshold} minutes`;
    } else {
      return `${ewtMinutes} minutes`;
    }
  };
  /**
   * Cancel Callback request
   */
  cancel() {
    this.contactService.returnToContactUs();
    this.store.dispatch(fromActions.clearEWT());
    this.store.dispatch(fromActions.callbackIsCancellable());
  }

  /**
   * Submit Callback request form
   */
  onSubmit(): void {
    sessionStorage['c2c_reason'] = this.requestForm.get('reason').value;
    sessionStorage['c2c_callbackNumber'] = this.cleanPhoneNumber(this.requestForm.get('callbackNumber').value);
    this.requestForm.reset();

    this.store.dispatch(fromActions.setCallbackScreen({ screen: 'thankYou' }));
    this.store.dispatch(fromActions.setContactVisibleComponent({ component: 'postCallbackForm' }));

    window.dispatchEvent(
      new CustomEvent('InitiateCallbackRequest', { detail: { queue: this.contactService.callbackVQ } })
    );
  }

  /**
   * Helper method to clean phone number
   * @param {string} num
   * @returns {string}
   */
  cleanPhoneNumber(num: string): string {
    const re = /[a-zA-Z ,.*&^%$#@!~`/?"';:\[\]\{\}\\|+_\-=()]/g;
    const cleanedNum = num.replace(re, '');
    return cleanedNum;
  }

  /**
   * Helper method to validate phone number is ten digits long
   * @param {AbstractControl} control
   * @returns {({ [key: string]: any } | null)}
   */
  validatePhone(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      const re = /[a-zA-Z ,.*&^%$#@!~`/?"';:\[\]\{\}\\|+_\-=()]/g;
      const cleanedNum = control.value.replace(re, '');
      if (cleanedNum && cleanedNum.length != 10) {
        return { phoneNumberInvalid: true };
      }
    }
    return null;
  }
  /**
   * Used to set form field values to existing value in sessionStorage if exists, otherwise to zero-length string
   *
   * @param {*} key
   * @returns
   */
  storageValOrEmpty(key) {
    return sessionStorage[key] ? sessionStorage[key] : '';
  }
  /**
   * Helper method
   *
   * @param {*} key
   * @param {*} value
   */
  setValue(key, value) {
    sessionStorage[key] = value;
  }

  /**
   * Unsubscribes from various streams
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  /**
   * DEPRECATED?: Validate
   * @param form
   * @returns
   */
  private getChangedProperties(form): any[] {
    const changedProperties = [];

    Object.keys(form.controls).forEach((name) => {
      const currentControl = form.controls[name];

      if (currentControl.dirty) changedProperties.push({ name: name, value: currentControl.value });
    });

    return changedProperties;
  }

  get callbackNumber() {
    return this.requestForm.get('callbackNumber');
  }

  get reason() {
    return this.requestForm.get('reason');
  }
}
