<div id="c2c_container">
  <nav id="c2e_headerButtonContainer" class="btn-group" role="group">
    <a
      href="#"
      id="c2e_button_chat"
      (click)="loadTab('chat',$event)"
      [class.active]="(activeTab$ | async) === 'chat'"
      class="button btn"
      tabindex="0"
    >
      <span id="c2e_chatWrapper" class="c2e_buttonContentWrapper">
        <div id="c2e_chatCountBubbleContainer">
          <div class="c2e_chatcountBubblewrapper">
            <div *ngIf="(unreadMsgCount$ | async) > 0 && (activeTab$ | async) !== 'chat'" class="chatBubble">
              {{ unreadMsgCount$ | async }}
            </div>
          </div>
        </div>
        <i class="icon icon-chat" aria-hidden="true"></i> </span
      >Chat
    </a>
    <a
      id="c2e_button_faq"
      *ngIf="!(faqUrl$ | safeAsync)?.error"
      (click)="loadTab('faq',$event)"
      class="button btn"
      [class.active]="(activeTab$ | async) === 'faq'"
      ><i class="icon icon-questions" aria-hidden="true"></i>FAQ</a
    >
    <a
      id="c2e_button_contact"
      *ngIf="(contactUsId$ | async) && !(contactUsError$ | async) && (contactUsId$ | async) !== 'none' "
      (click)="loadTab('contact',$event)"
      class="button btn"
      [class.active]="(activeTab$ | async) === 'contact'"
      ><i class="icon icon-call" aria-hidden="true"></i>Contact</a
    >
  </nav>
</div>
<div id="content">
  <app-chat [hidden]="(activeTab$ | async) !== 'chat'"></app-chat>
  <app-faq *ngIf="!(faqUrl$ | safeAsync)?.error" [hidden]="(activeTab$ | async) !== 'faq'"></app-faq>
  <app-contact
    *ngIf="contactUsId$ | async"
    [hidden]="(activeTab$ | async) !== 'contact' || (contactUsError$ | async) || (contactUsId$ | async) === 'none'"
  ></app-contact>
</div>
