import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import * as ChatActions from '../actions/chat.actions';
import * as AppState from '../app-state';

/**
 * Key for NgRX Store Chat feature selector
 */
export const chatFeatureKey = 'chat';

/**
 * Interface adding ChatState to main App state
 *
 * @export
 * @interface State
 * @extends {AppState.State}
 */
export interface State extends AppState.State {
  /**
   * NgRX Chat State
   *
   * @type {ChatState}
   */
  chat: ChatState;
}

export interface ChatState {
  /**
   * Active Chat
   *
   * @type {boolean}
   */
  activeChat: boolean;

  /**
   * Unread Messages functionality
   *
   * @type {(number | null)}
   */
  unreadMessageCount: number | null;
  /**
   * Chat is loading
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Show error message
   *
   * @type {boolean}
   */
   showError: boolean;

  /**
   * DETERMINE WHAT THIS DOES; is it to stop reloading of userData once form has been submitted?
   */
  doNotLoadData: boolean;

  /**
   * Active tab is Chat
   */
  activeChatWindow: boolean;

    /**
   * sets true if autosubmit Icon Proactive chat
   *
   * @type {boolean}
   */
  autoSubmitProactiveChat: boolean;
}

/**
 * Initial ChatState
 */
export const initialState: ChatState = {
  activeChat: false,
  unreadMessageCount: 0,
  loading: true,
  showError: false,
  doNotLoadData: false,
  activeChatWindow: false,
  autoSubmitProactiveChat: false
};

/**
 * NgRX Chat reducer
 */
export const reducer = createReducer<ChatState>(
  initialState,
  on(
    ChatActions.toggleActiveChat,
    (state): ChatState => {
      return {
        ...state,
        activeChat: !state.activeChat,
      };
    }
  ),
  on(
    ChatActions.toggleActiveChatWindow,
    (state): ChatState => {
      return {
        ...state,
        activeChatWindow: !state.activeChatWindow,
      };
    }
  ),
  on(
    ChatActions.clearUnreadMessages,
    (state): ChatState => {
      return {
        ...state,
        unreadMessageCount: 0,
      };
    }
  ),
  on(
    ChatActions.incrementUnreadMessages,
    (state): ChatState => {
      return {
        ...state,
        unreadMessageCount: state.unreadMessageCount + 1,
      };
    }
  ),

  on(ChatActions.activeChatTrue, (state: ChatState) => {
    return { ...state, activeChat: true };
  }),
  on(ChatActions.activeChatFalse, (state: ChatState) => {
    return { ...state, activeChat: false };
  }),
  on(ChatActions.isLoading, (state: ChatState) => {
    return { ...state, loading: true };
  }),
  on(ChatActions.isNotLoading, (state: ChatState) => {
    return { ...state, loading: false };
  }),
  on(ChatActions.doNotLoadDataIsTrue, (state: ChatState) => {
    return { ...state, doNotLoadData: true };
  }),
  on(ChatActions.doNotLoadDataIsFalse, (state: ChatState) => {
    return { ...state, doNotLoadData: false };
  }),
  on(ChatActions.activeChatWindowTrue, (state: ChatState) => {
    return { ...state, activeChatWindow: true };
  }),
  on(ChatActions.activeChatWindowFalse, (state: ChatState) => {
    return { ...state, activeChatWindow: false };
  }),
  on(ChatActions.autosubmitProactiveChatTrue, (state: ChatState) => {
    return { ...state, autoSubmitProactiveChat: true };
  }),
  on(ChatActions.autosubmitProactiveChatFalse, (state: ChatState) => {
    return { ...state, autoSubmitProactiveChat: false };
  }),
  on(ChatActions.showError, (state: ChatState) => {
    return { ...state, showError: true };
  }),
  on(ChatActions.hideError, (state: ChatState) => {
    return { ...state, showError: false };
  })
);
