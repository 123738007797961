import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, interval, of, Observable, Subject } from 'rxjs';
import { tap, catchError, map, switchMap, withLatestFrom, mergeMap, takeUntil } from 'rxjs/operators';
import { ContactUsData } from '../../contact/contact-us-data.component';
import { ContactService } from '../../contact/contact.service';
import * as fromActions from '../actions/contact.actions';
import { State, getEWStatus, getActiveTab } from '../reducers';
import { getCallbackState } from '../selectors/contact.selectors';

/**
 * NgRX Effects for Contact page primarily related to Callback requests
 *
 * @export
 * @class ContactEffects
 */
@Injectable()
export class ContactEffects {
  /**
  Indicates whether/not Engagement Widget is open
   */
  ewOpenStatus$: Observable<boolean>;

  /**
  String value of ewOpenStatus$
  */
  ewOpenStatus: boolean;

  /** Active tab (observable)
  @type {Observable<string>}
  */
  activeTab$: Observable<string>;

  /** Current Tab name */
  tabName: string;

  unsubscribe$: Subject<boolean> = new Subject();

  count : number = 0;

  constructor(private actions$: Actions, private store: Store<State>, private contactService: ContactService) {
    this.ewOpenStatus$ = this.store.select(getEWStatus);
    this.activeTab$ = this.store.select(getActiveTab);

    this.ewOpenStatus$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.ewOpenStatus = data;
    });

    this.activeTab$.pipe(takeUntil(this.unsubscribe$)).subscribe((tab) => {
      this.tabName = tab;
    });
  }

  /**
   * When Contact ID is set, triggers Genesys API token request
   */
  setContactUs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.setContactUsId),
        tap(() => {
          this.store.dispatch(fromActions.acquireAuthorizationToken());
          // console.log('setContactUs effect');
        })
      ),
    { dispatch: false }
  );

  /**
   * Acquires Genesys API authorization token
   *
   * If valid token, triggers setToken$ effect via setAuthorizationToken action
   */
  getAuthToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.acquireAuthorizationToken),
        switchMap(() => {
          return this.contactService.acquireAuthorizationToken().pipe(
            map((res) => {
              this.store.dispatch(fromActions.setAuthorizationToken({ token: res }));
            }),
            catchError((error) => {
              console.log(error);
              return error;
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * When authorization token is set, triggers request for Contact Us data
   */
  setToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.setAuthorizationToken),
        switchMap(() => {
          return this.contactService.getAnswer(this.contactService.contactUsId).pipe(
            switchMap((res) => {
              if (this.contactService.contactUsError == '') {
                this.store.dispatch(fromActions.setContactUsObject({ object: res }));
                return of(res);
              } else {
                return EMPTY;
              }
            }),

            catchError((error) => {
              console.log(error);
              return error;
            })
          );
        })
      ),
    { dispatch: false }
  );

  processContactUsData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.setContactUsObject),
        map((contactUsObject) => {
          let arr = [];
          let vqCount = 0;
          const obj = Object.values(contactUsObject.object);
          obj.forEach((element: ContactUsData) => {
            vqCount = element.genesysSkillVQ !== '' ? ++vqCount : vqCount;
            arr.push(element);
          });

          arr = arr.sort((a, b) => {
            return a.displayOrder - b.displayOrder;
          });

          const output = { count: vqCount, array: arr };
          return output;
        }),
        map((obj) => {
          if (obj['count'] > 0) {
            this.store.dispatch(fromActions.triggerEwtEffect({ object: obj['array'] }));
            interval(60000)
              .pipe(
                takeUntil(this.contactService.unsubscribe$),
                takeUntil(this.actions$.pipe(ofType(fromActions.setContactUsObject)))
              )
              .subscribe(() => {
                this.store.dispatch(fromActions.triggerEwtEffect({ object: this.contactService.contactUsArray }));
              });
          } else {
            this.store.dispatch(fromActions.setContactUsArray({ array: obj['array'] }));
          }
          return of(obj);
        }),
        mergeMap((res) => {
          return res;
        })
      );
    },
    { dispatch: false }
  );

  processEwtData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.triggerEwtEffect),
        switchMap(() => {
          this.count++;
          if(this.count > 1)
            return EMPTY;
          else {
            const vqList = this.contactService.generateVqList(this.contactService.contactUsObject);
            return this.contactService.getEWT(vqList);
          }
        }),
        map((vqData) => {
          const ewtData = this.contactService.parseEwtObject(vqData['data']);
          this.store.dispatch(fromActions.setCallbackEwtObject({ object: ewtData }));
          return this.contactService.ewtObject;
        }),
        withLatestFrom(this.contactService.contactUsObject$),
        map(([ewtRow, contactRow]) => {
          let arr = [];
          Object.keys(contactRow).map((contactKey) => {
            const contact = contactRow[contactKey];
            const ewt = ewtRow[contactKey] ? ewtRow[contactKey]['ewt'] : -1;

            const output = { ...(contact as ContactUsData), estimatedWaitTime: ewt };
            arr.push(output);
          });
          arr = arr.sort((a, b) => {
            return a.displayOrder - b.displayOrder;
          });
          return arr;
        }),
        tap((res) => {
          this.store.dispatch(fromActions.setContactUsArray({ array: res }));
        }),

        catchError((error) => {
          console.log(error);
          return error;
        })
      );
    },
    { dispatch: false }
  );

  clickOnRequestACallLink$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.initiateCallbackForm),
        withLatestFrom(this.store.select(getCallbackState)),
        map(([action, activeCallbackState]) => {
          if (!activeCallbackState) {
            parent.postMessage('sendCBValues', '*');
            this.store.dispatch(fromActions.showCallbackForm());
            this.store.dispatch(fromActions.setContactVisibleComponent({ component: 'callbackForm' }));
          } else {
            this.store.dispatch(fromActions.setContactVisibleComponent({ component: 'postCallbackForm' }));
          }
        })
      );
    },
    { dispatch: false }
  );

  setVqEwt$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.setCallbackVQ),
        tap(() => {
          const vq = this.contactService.callbackVQ;
          //console.log(this.contactService.ewtObject[vq]['ewt']);

          const ewt = this.contactService.ewtObject[vq]['ewt'];
          this.store.dispatch(fromActions.setEWT({ ewt: ewt }));
        })
      );
    },
    { dispatch: false }
  );

  /**
   * Triggers when callback screen is set to 'connected'
   */
  connectedToContactUs$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.setCallbackScreen),
        tap((action) => {
          if (action.screen === 'connected' && this.ewOpenStatus && this.tabName === 'contact') {
            setTimeout(() => {
              this.store.dispatch(fromActions.hideCallbackForm());
              this.store.dispatch(fromActions.setCallbackScreen({ screen: '' }));
              this.store.dispatch(fromActions.setContactVisibleComponent({ component: 'contact' }));
              this.store.dispatch(fromActions.callbackIsCancellable());
              this.store.dispatch(fromActions.activeCallbackIsFalse());
            }, 30000);
          }
        })
      );
    },
    { dispatch: false }
  );
  
  getPriority$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.acquirePriority),
        switchMap(() => {
          return this.contactService.acquirePriority(sessionStorage['scoringModelPayload'], sessionStorage['token'], sessionStorage['qcn'], sessionStorage['guid']).pipe(
            map((res) => {
              this.store.dispatch(fromActions.setPriority({ priority: res }));
              parent.postMessage({ name: 'setCallbackPriority', priority: res }, '*');
            }),
            catchError((error) => {
              console.log(error);
              return error;
            })
          );
        })
      ),
    { dispatch: false }
  );
}
