import { Injectable, OnInit } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';
import { State } from '../reducers';
import { changeTabs } from '../actions/tabs.actions';
import { getActiveChatStatus } from '../selectors/chat.selectors';
import { clearUnreadMessages } from '../actions/chat.actions';
import { UnreadMessagesService } from '../../services/unread-msgs.service';
/**
 * NgRX Effects for Tabs
 *
 * @export
 * @class TabsEffects
 */
@Injectable()
export class TabsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private unreadMsgService: UnreadMessagesService
  ) {}

  /**
   * DEPRECATED?: Validate
   *
   * @type {Observable<boolean>}
   */
  activeChatStatus$: Observable<boolean>;

  /**
   * Triggers onChangeTab() when changeTabs action is triggered
   */
  getTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeTabs),

        tap((data) => {
          this.onChangeTab(data.id);
        })
      ),
    { dispatch: false }
  );
  /**
   * Fires message to parent for data analytics indicating tab is clicked
   *
   */
  onChangeTab = (tab: string): void => {
    parent.postMessage(`c2e_clicked${tab}`, '*');
    // switch (tab) {
    //   case 'chat':
    //     parent.postMessage(`c2e_clicked${tab}`, '*');
    //     break;
    //   case 'faq':
    //     parent.postMessage(`c2e_clicked${tab}`, '*');
    //     // $('.cx-window-manager').hide();
    //     break;
    //   case 'contact':
    //     parent.postMessage(`c2e_clicked${tab}`, '*');
    //     // $('.cx-window-manager').hide();
    //     break;
    // }
  };
}
