import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

/**
 * Contains HTML to display chat transcript
 *
 * @export
 * @class ChatWindowComponent
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
})

export class ChatWindowComponent implements AfterViewInit {
  /**
   * Reference to chat input field
   *
   * @type {ElementRef}
   * @memberof ChatWindowComponent
   */
  @ViewChild('chatInput') input: ElementRef;

  /**
   * Detects user typing in chat input box and notifies Genesys code to chatter sees typing indicator
   */
  ngAfterViewInit(): void {
    const x = new Event('ChatWindowLoaded');
    window.dispatchEvent(x);
    // server-side search
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          event = new Event('UserTyping');
          window.dispatchEvent(event);
        })
      )
      .subscribe();
  }
}
