import { createSelector } from '@ngrx/store';
import { selectContactState } from '../reducers';
import { ContactState } from '../reducers/contact.reducer';

/**
 * OKA API Authorization Token
 */
export const getAuthorizationToken = createSelector(selectContactState, (s: ContactState) => s.authorizationToken);

/**
 * OKA Article ID
 */
export const getContactUsId = createSelector(selectContactState, (s: ContactState) => s.contactUsId);

/**
 * Error when retrieving Contact data from OKA API
 */
export const getContactUsError = createSelector(selectContactState, (s: ContactState) => s.contactUsError);

/**
 * Active callback status (boolean)
 */
export const getCallbackState = createSelector(selectContactState, (s: ContactState) => s.activeCallback);

/**
 * Callback should be displayed (per data from host site)
 */
export const getCallbackEligibilityState = createSelector(selectContactState, (s: ContactState) => s.callbackEligible);

/**
 * View to display on Contact tab
 *
 * Options: contact | callbackForm | postCallbackForm
 */
export const getContactVisibleComponent = createSelector(
  selectContactState,
  (s: ContactState) => s.contactVisibleComponent
);
/**
 * Get screen to display after callback request has been submitted
 */
export const getCallbackPostScreen = createSelector(selectContactState, (s: ContactState) => s.callbackPostScreen);

/**
 * Error text on Callback request
 */
export const getCallbackRequestError = createSelector(selectContactState, (s: ContactState) => s.callbackRequestError);

/**
 *  Callback phone number
 */
export const getCallbackPhoneNumber = createSelector(selectContactState, (s: ContactState) => s.activeCallbackNumber);

/**
 * Is callback cancellable
 */
export const getCallbackCancellableStatus = createSelector(
  selectContactState,
  (s: ContactState) => s.callbackCancellable
);

/**
 * Genesys Callback ID
 */
export const getCallbackId = createSelector(selectContactState, (s: ContactState) => s.callbackId);

/**
 * Get ewt (estimated wait time) from Store
 */
export const getEWT = createSelector(selectContactState, (s: ContactState) => s.ewt);

/**
 * Callback VQ
 */
export const getCallbackVQ = createSelector(selectContactState, (s: ContactState) => s.callbackVQ);

export const getContactUsArray = createSelector(selectContactState, (s: ContactState) => s.contactUsArray);

export const getCallbackEwtArray = createSelector(selectContactState, (s: ContactState) => s.cbEwtArray);

export const getContactUsObject = createSelector(selectContactState, (s: ContactState) => s.contactUsObject);

export const getCallbackEwtObject = createSelector(selectContactState, (s: ContactState) => s.callbackEwtObject);

export const getPolicyNumbers = createSelector(selectContactState, (s: ContactState) => s.policyNumber);

export const getDirectToCallback = createSelector(selectContactState, (s: ContactState) => s.directToCallback);

/**
 * Scoring model Priority
 */
 export const getPriority = createSelector(selectContactState, (s: ContactState) => s.priority);
