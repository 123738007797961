import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getFaqUrl, State } from '../store/reducers';
import { select, Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * FAQ Tab Component
 *
 * @export
 * @class FaqComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit, OnDestroy {
  /**
   * Creates an instance of FaqComponent.
   * @param {DomSanitizer} sanitizer
   * @param {Store<State>} store
   */
  constructor(private sanitizer: DomSanitizer, private store: Store<State>) {}

  /**
   *  FAQ tab URL
   *
   * @type {Observable<string>}
   * @memberof FaqComponent
   */
  faqUrl$: Observable<string>;

  /**
   * Subscribed faqUrl value used to set tab source
   *
   * @type {string}
   * @memberof FaqComponent
   */
  url: string;
  /**
   * Used to unsubscribe from subscriptions on ngDestroy
   *
   * @type {Subject<boolean>}
   */
  unsubscribe$: Subject<boolean> = new Subject();

  /**
   * Angular ngOnInit
   */
  ngOnInit(): void {
    this.faqUrl$ = this.store.select(getFaqUrl);
    this.faqUrl$.pipe(takeUntil(this.unsubscribe$)).subscribe((url) => (this.url = url));
  }

  /**
   * Angular ngOnDestroy
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
