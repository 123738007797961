import { DropdownQuestion } from './cbform-questions-dropdown';
import { TextboxQuestion } from './cbform-questions-textbox';

export const FORM_DEFAULT_FIELDS = [
  new TextboxQuestion({
    key: 'callbackNumber',
    label: 'Callback Number',
    required: true,
    validator: 'phoneLength',
    errorMessage: 'Please enter a valid 10 digit phone number.',
    order: 3,
  }),
  new TextboxQuestion({
    key: 'subject',
    label: 'Reason for Callback',
    required: true,
    errorMessage: 'Please enter a subject',
    order: 4,
  }),
];

export const FORM_EBC_FIELDS = [
  new TextboxQuestion({
    key: 'policyNumber',
    label: 'Policy Number',
    required: false,
    order: 7,
  }),
  ...FORM_DEFAULT_FIELDS,
];
