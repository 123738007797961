import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store/reducers';
import * as fromActions from '../store/actions/contact.actions';
import * as fromSelectors from '../store/selectors/contact.selectors';
import { Observable, Subject } from 'rxjs';
import { ContactService } from './contact.service';
import { takeUntil } from 'rxjs/operators';

/**
 * Callback Component
 *
 * @export
 * @class ContactCbPostComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-contact-cb-post',
  templateUrl: './contact-cb-post.component.html',
  styleUrls: ['./contact-cb-post.component.scss'],
})
export class ContactCbPostComponent implements OnInit, OnDestroy {
  /**
   * Used to unsubscribe from subscriptions on ngDestroy
   * @type {Subject<boolean>}
   */
  unsubscribe$: Subject<boolean> = new Subject();
  /**
   * Screen to display after Callback request is submitted
   * @type {Observable<string>}
   */
  callbackPostScreen$: Observable<string>;

  /**
   * Screen to display after Callback request is submitted (subscribed value)
   * @type {string}
   */
  callbackPostScreen: string;

  /**
   * Callback phone number
   */
  callbackNumber$;

  /**
   * Genesys Callback ID
   *
   * @type {Observable<string>}
   */
  callbackId$: Observable<string>;

  /**
   * Genesys Callback ID (subscribed value)
   *
   * @type {string}
   */
  callbackId: string;

  /**
   * Callback is cancellable
   * @type {Observable<boolean>}
   */
  cancellable$: Observable<boolean>;

  /**
   * DEPRECATED?: Validate
   */
  callbackNumber;

  /**
   * Active Callback status
   * @type {Observable<boolean>}
   */
  activeCallbackState$: Observable<boolean>;

  /**
   * Active Callback status (subscribed value)
   * @type {boolean}
   */
  activeCallbackState: boolean;

  /**
   *Creates an instance of ContactCbPostComponent.
   * @param {Store<State>} store
   * @param {ContactService} contactService
   */
  constructor(private store: Store<State>, private contactService: ContactService) {}

  /**
   * Angular ngOnInit
   */
  ngOnInit(): void {
    this.callbackPostScreen$ = this.store.select(fromSelectors.getCallbackPostScreen);
    this.callbackPostScreen$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((screen) => (this.callbackPostScreen = screen));
    this.callbackNumber$ = this.store.select(fromSelectors.getCallbackPhoneNumber);
    this.callbackId$ = this.store.select(fromSelectors.getCallbackId);
    this.callbackId$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => (this.callbackId = data));
    this.activeCallbackState$ = this.store.select(fromSelectors.getCallbackState);
    this.activeCallbackState$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => (this.activeCallbackState = data));
    this.cancellable$ = this.store.select(fromSelectors.getCallbackCancellableStatus);
    window.dispatchEvent(new Event('CBScreensInitiated'));
  }

  /**
   * Makes pre-Callback Contact view active
   */
  returnToContactUs(): void {
    this.contactService.returnToContactUs();
    if (!this.activeCallbackState) {
      this.store.dispatch(fromActions.setCallbackScreen({ screen: '' }));
    }
  }

  /**
   * Post message to Genesys to cancel callback request
   */
  cancelCallback(): void {
    const event = new CustomEvent('CancelCallback', {
      detail: { callbackId: this.callbackId },
    });
    window.dispatchEvent(event);
  }

  /**
   * Unsubscribes from various streams
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
