import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromActions from '../actions/general.actions';
import * as AppState from '../app-state';
/**
 * Key for NgRX Store General selector
 */
export const generalFeatureKey = 'general';

/**
 * Interface adding GeneralState to main App state
 *
 * @export
 * @interface State
 * @extends {AppState.State}
 */
export interface State extends AppState.State {
  /**
   * NgRX Chat State
   *
   * @type {ChatState}
   */
  general: GeneralState;
}
export interface GeneralState {
  appName: string;
  environmentType: string;
}

export const initialState: GeneralState = { appName: '', environmentType: '' };

export const reducer = createReducer<GeneralState>(
  initialState,
  on(fromActions.setAppName, (state, { appName }) => ({ ...state, appName: appName })),
  on(fromActions.setEnvironmentType, (state, { envType }) => ({ ...state, environmentType: envType }))
);
