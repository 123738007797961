import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, of, Observable, EMPTY } from 'rxjs';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { State } from '../store/reducers';
import * as fromSelectors from '../store/selectors/contact.selectors';
import * as fromActions from '../store/actions/contact.actions';
import * as fromTabsAction from '../store/actions/tabs.actions';
import * as fromGeneralSelectors from '../store/selectors/general.selectors';
import * as xml2js from 'xml2js';
import {
  ContactUsById,
  ContactUsData,
  EwtById,
  GenesysEwtRowData,
  GenesysEwtVqData,
  ScoringModelPayload,
} from './contact-us-data.component';
/**
 * Contact tab related service
 *
 * Authorizes access to and requests data from OKA for Contact page text
 *
 * @export
 * @class ContactService
 * @implements {OnDestroy}
 */
@Injectable({
  providedIn: 'root',
})
export class ContactService implements OnDestroy {
  ewtArray$: Observable<Array<GenesysEwtVqData>>;
  ewtArray: Array<GenesysEwtVqData>;
  contactUsArray$: Observable<Array<ContactUsData>>;
  contactUsArray: Array<ContactUsData>;
  contactUsObject$: Observable<ContactUsById>;
  contactUsObject: ContactUsById;
  ewtObject$: Observable<GenesysEwtRowData>;
  ewtObject: GenesysEwtRowData;

  ewtApiKey = 'w2mbNLGJqJFUFRPbrdJR4Z0TiOnF7t44CeBdjB0j';
  /**
   * OKA Authentication Token
   */
  authToken$;

  /**
   * OKA Authentication Token subscribed value
   */
  authToken = '';

  /**
   * OKA Authentication API URL
   *
   * @private
   * @type {string}
   */
  private authUrl: string;

  /**
   * OKA Content API URL
   *
   * @private
   * @type {string}
   */
  private contentUrl: string;

  /**
   * Used to unsubscribe from subscriptions on ngDestroy
   *
   * @type {Subject<boolean>}
   * @memberof TabsComponent
   */
  unsubscribe$: Subject<boolean> = new Subject();

  /**
   * OKA Article ID used to get Contact page text
   *
   * @type {Observable<string>}
   */
  contactUsId$: Observable<string>;

  /**
   * OKA Article ID (subscribed value)
   *
   * @type {string}
   */

  contactUsId: string;
  /**
   * Callback Phone NUmber
   *
   * @type {Observable<string>}
   */
  callbackPhoneNumber$: Observable<string>;

  /**
   * Callback Phone Number (subscribed value)
   *
   * @type {string}
   *
   */
  callbackPhoneNumber: string;

  callbackVQ$: Observable<string>;
  callbackVQ: string;

  ewt$: Observable<number>;
  ewt: number;

  environmentType$: Observable<string>;
  environmentType: string;

  contactUsError$: Observable<any>;
  contactUsError: any;

  policyNumber$: Observable<string>;
  policyNumber: string;

  priority$: Observable<number>;
  priority: number;
  priorityBaseUrl: string;
  /**
   * Contact Us Error Message if unable to locate article
   */
  errorText = 'Unable to locate article';

  constructor(private httpClient: HttpClient, private store: Store<State>) {
    this.authToken$ = this.store.select(fromSelectors.getAuthorizationToken);
    this.authToken$.pipe(takeUntil(this.unsubscribe$)).subscribe((token) => {
      this.authToken = token;
    });
    this.authUrl = 'https://thehartford-support-irs.custhelp.com/km/api/latest/auth/integration/authorize';
    this.contentUrl = 'https://thehartford-support-irs.custhelp.com/km/api/latest/content/answers/';
    this.contactUsId$ = this.store.select(fromSelectors.getContactUsId);
    this.contactUsId$.pipe(takeUntil(this.unsubscribe$)).subscribe((contactUsId) => (this.contactUsId = contactUsId));
    this.callbackPhoneNumber$ = this.store.select(fromSelectors.getCallbackPhoneNumber);
    this.callbackPhoneNumber$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((callbackPhoneNumber) => (this.callbackPhoneNumber = callbackPhoneNumber));
    this.callbackVQ$ = this.store.select(fromSelectors.getCallbackVQ);
    this.callbackVQ$.pipe(takeUntil(this.unsubscribe$)).subscribe((vq) => (this.callbackVQ = vq));
    this.contactUsObject$ = this.store.select(fromSelectors.getContactUsObject);
    this.contactUsObject$.pipe(takeUntil(this.unsubscribe$)).subscribe((object) => (this.contactUsObject = object));
    this.contactUsArray$ = this.store.select(fromSelectors.getContactUsArray);
    this.contactUsArray$.pipe(takeUntil(this.unsubscribe$)).subscribe((array) => (this.contactUsArray = array));
    this.ewtArray$ = this.store.select(fromSelectors.getCallbackEwtArray);
    this.ewtArray$.pipe(takeUntil(this.unsubscribe$)).subscribe((array) => (this.ewtArray = array));
    this.ewtObject$ = this.store.select(fromSelectors.getCallbackEwtObject);
    this.ewtObject$.pipe(takeUntil(this.unsubscribe$)).subscribe((object) => (this.ewtObject = object));
    this.ewt$ = this.store.select(fromSelectors.getEWT);
    this.ewt$.pipe(takeUntil(this.unsubscribe$)).subscribe((ewt) => (this.ewt = ewt));
    this.environmentType$ = this.store.select(fromGeneralSelectors.getEnvironmentType);
    this.environmentType$.pipe(takeUntil(this.unsubscribe$)).subscribe((env) => (this.environmentType = env));
    this.contactUsError$ = this.store.select(fromSelectors.getContactUsError);
    this.contactUsError$.pipe(takeUntil(this.unsubscribe$)).subscribe((error) => (this.contactUsError = error));
    this.policyNumber$ = this.store.select(fromSelectors.getPolicyNumbers);
    this.policyNumber$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((policyNumber) => (this.policyNumber = policyNumber));
    this.priority$ = this.store.select(fromSelectors.getPriority);
    this.priority$.pipe(takeUntil(this.unsubscribe$)).subscribe((priority) => (this.priority = priority));
  }

  private ewtHeaders = { 'x-api-key': this.ewtApiKey, content: 'application/json' };

  private testUrl = 'api/queueEwt';
  private ewtUrl = 'https://thehartford-gapi-use2.genesyscloud.com/engagement/v3/estimated-wait-time';

  /**
   * OKA API authentication credentials
   *
   * @private
   */
  private authCreds = JSON.stringify({
    login: 'c2e_ew',
    password: 'Hartford!234',
    siteName: 'thehartford_support',
  });

  /**
   * OKA API authentication request headers
   *
   * @private
   */
  private authReqHeaders = {
    headers: {
      kmauthtoken: '{"siteName":"thehartford_support","localeId":"en_US"}',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  /**
   * OKA API content request headers
   *
   * @private
   */
  private contentReqHeaders = {
    headers: {
      kmauthtoken: `{"siteName":"thehartford_support","localeId":"en_US","integrationUserToken":"${this.authToken}"}`,
    },
  };

  /**
   * Makes request for OKA API Authentication Token
   *
   * @returns
   */
  acquireAuthorizationToken() {
    return this.httpClient.post<Object>(this.authUrl, this.authCreds, this.authReqHeaders).pipe(
      take(1),
      map((result) => {
        this.authToken = result['authenticationToken'];
        return this.authToken;
      })
    );
  }

  /**
   * Makes request for OKA API Answer Data with matching ID
   *
   * If failure, hides Contact related elements
   * If answerId of 'none' is passed then remove contact tab from EW
   *
   * @param {(string | number)} answerId
   * @returns
   */
  getAnswer(answerId: string | number): Observable<any> {
    if (answerId !== 'none') {
      const headers = {
        headers: {
          kmauthtoken: `{"siteName":"thehartford_support","localeId":"en_US","integrationUserToken":"${this.authToken}"}`,
        },
      };
      return this.httpClient.get(`${this.contentUrl}${answerId}`, headers).pipe(
        take(1),
        map((result) => {
          const x = this.parseContactUsXml(result['xml']);
          this.store.dispatch(fromActions.clearContactUsError());
          return x;
        }),
        catchError((e) => {
          this.store.dispatch(fromActions.loadContactUsFailure({ error: `${e.status}: ${e.statusText}` }));
          this.store.dispatch(fromTabsAction.changeTabsNoPost({ id: 'chat' }));

          return of(this.errorText);
        })
      );
    } else {
      this.store.dispatch(fromTabsAction.changeTabsNoPost({ id: 'chat' }));
      return EMPTY;
    }
  }

  /**
   * Sets active tab to Contact
   */
  returnToContactUs(): void {
    this.store.dispatch(fromActions.setContactVisibleComponent({ component: 'contact' }));
  }

  /**
   * Triggers 'Request a Call' button click
   *
   * See {@link ../injectables/ContactEffects.html#requestCallbackClick$ | requestCallbackClick$ effect}
   */
  initiateCallbackForm(): void {
    this.store.dispatch(fromActions.initiateCallbackForm());
  }

  /**
   * Angular ngOnDestroy
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  /**
   * Parses OKA API Answer data
   *
   * @param {*} xml
   * @returns
   */
  getContactUsXml = (xml: string): string => {
    const contactStartString = '</CONTACT_US_NAME>';
    const parser = new xml2js.Parser({
      explicitArray: false,
    });

    let result;
    if (xml.includes(contactStartString)) {
      parser.parseString(xml, function (err, res) {
        result = JSON.stringify(res);
      });
    }
    return result;
  };

  parseContactUsXml = (xml: string): ContactUsById => {
    let out = {};
    const contactUsBlock = JSON.parse(this.getContactUsXml(xml))['CONTACT_US']['CONTACT_US_SECTION'];
    if (!contactUsBlock.length) {
      const extract: ContactUsById = this.extractData(contactUsBlock, '0');
      out = { ...extract };
    } else {
      contactUsBlock.forEach((element, index) => {
        const extract: ContactUsById = this.extractData(element, index);
        out = { ...out, ...extract };
      });
    }

    return out;
  };

  extractData = (dataBlock, index): ContactUsById => {
    const key = dataBlock.GENESYS_SKILL_NAME?.VALUE
      ? this.generateFullVqName(dataBlock.GENESYS_SKILL_NAME.VALUE)
      : String(index);

    const output: ContactUsById = {
      [key]: {
        name: dataBlock.CONTACT_US_SECTION_NAME,
        hoursOfOperation: dataBlock.HOURS_OF_OPERATION ?? '',
        hoursOfOperation2: dataBlock.HOURS_OF_OPERATION_2 ?? '',
        displayCallback: dataBlock.DISPLAY_QUEUE_CALLBACK == 'Y' ? true : false,
        phoneNumber: dataBlock.PHONE_NUMBER ?? '',
        genesysSkillDisplayName: dataBlock.GENESYS_SKILL_NAME?.DISPLAY ?? '',
        genesysSkillVQ: dataBlock.GENESYS_SKILL_NAME?.VALUE
          ? this.generateFullVqName(dataBlock.GENESYS_SKILL_NAME.VALUE)
          : '',
        displayOrder: dataBlock.DISPLAY_ORDER ?? 100,
        brandID: dataBlock.CALLBACK_BRAND_ID ?? '',
      },
    };

    return output;
  };

  /**
   * If rows has Genesys Skill VQ, determines whether should point to Prod or QA
   * @param vqBase
   * @returns
   */
  generateFullVqName = (vqBase: string): string => {
    let vqOut: string;
    this.environmentType === 'prod' ? (vqOut = `TheHartford_${vqBase}`) : (vqOut = `TheHartfordQA_${vqBase}`);
    return vqOut;
  };
  /**
   * If row has a Genesys Skill VQ, return the VQ name and add to comma-delimited string
   *
   * @param {Array<ContactUsData>} obj
   */
  generateVqList = (obj: ContactUsById): string => {
    let output;
    const preReduce = Object.keys(obj)
      .map((row) => {
        return row;
      })
      .filter((rowKey) => {
        return rowKey.length > 2;
      });

    if (preReduce.length > 0) {
      output = preReduce.reduce((llist, rowKey, index) => {
        if (index === 0) {
          return llist.concat(rowKey);
        } else {
          return llist.concat(`,${rowKey}`);
        }
      });
    } else {
      output = '';
    }
    return output;
  };

  getEWT = (vqList: string): Observable<any> => {
    const params = new HttpParams().set(`virtual-queues`, vqList);
    const options = { params: params, headers: this.ewtHeaders };
    let output;
    if (window.location.hostname.includes('localhost')) {
      vqList.includes(',')
        ? (output = this.httpClient.get('http://localhost:3000/ewt', options).pipe(map((data) => data[0])))
        : (output = this.httpClient.get('http://localhost:3000/ewtSingle', options).pipe(map((data) => data[0])));
    } else output = this.httpClient.get(this.ewtUrl, options);
    return output;
  };

  parseEwtObject = (ewtObject: Observable<any>): EwtById => {
    const out = {};
    ewtObject.forEach((element) => {
      const key = element.virtualQueue;
      out[key] = {
        virtualQueue: element.virtualQueue,
        ewt: element.estimatedWaitTime,
      };
    });
    return out;
  };

  initCallbackForm = (vq: string): void => {
    this.store.dispatch(fromActions.setCallbackVQ({ vq: vq }));
    window.parent.postMessage('C2C_REQUESTACALL_LOAD', '*');
    if (sessionStorage['CHAT_policyNumbers'] !== null) {
      this.store.dispatch(fromActions.setPolicyNumber({ policyNumber: sessionStorage['CHAT_policyNumbers'] }));
    }
    this.store.dispatch(fromActions.initiateCallbackForm());
  };

  getCallbackBrandId = (vq: string): string => {
    return this.contactUsObject[vq].brandID;
  };

   /**
   * Makes request for Scoring model API for getting priority
   * @param {ScoringModelPayload} payload
   * @returns
   */
    acquirePriority(payload: ScoringModelPayload, token: string, qcn: string, guid: string) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'sourceSystemName': 'SALES LEAD',
          'client-request-id': '1149',
          'X-IBM-Client-Id': sessionStorage.getItem('priorityClientId'),
          'X-IBM-Client-Secret':sessionStorage.getItem('priorityClientSecret')
        })
      };
      this.priorityBaseUrl =
        this.environmentType === 'prod'
          ? 'https://apis-internal.thehartford.com/leadsegmentation/v1/prioritylevel'
          : this.setNonProdPriorityBaseUrl();
      return this.httpClient
        .post<Object>(this.priorityBaseUrl, payload, httpOptions)
        .pipe(
          take(1),
          map((result) => {
            return result['priority'];
          })
        );
    }

    setNonProdPriorityBaseUrl(): string {
      const hostname = window.location.hostname;
      let baseUrl = '';
      switch (hostname) {
        case 'qa-quote.thehartford.com':
        case 'ltq-quote.thehartford.com':
          baseUrl = 'https://qa-apis-internal.thehartford.com/leadsegmentation/v1/prioritylevel' 
          break;
        case 'int-quote.thehartford.com':
        case 'lti-quote.thehartford.com':
          baseUrl = 'https://int-apis-internal.thehartford.com/leadsegmentation/v1/prioritylevel' 
          break;
        default:
          baseUrl = 'https://qa-apis-internal.thehartford.com/leadsegmentation/v1/prioritylevel' 
          break;
      }
      return baseUrl;
    }
}
