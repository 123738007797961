/**
 * Map between Engagement Engine custom data fields and Genesys KVPs
 */

export const KVP_MAP = {
  'incidents.subject': 'subject',
  'contacts.first_name': 'firstname',
  'contacts.last_name': 'lastname',
  'contacts.email': 'email',
  'incidents.prod': 'CHAT_product',
  'incidents.cat': 'CHAT_category',
  1: 'CHAT_pageTitle',
  2: 'CHAT_userId',
  3: 'CustomerSegment',
  4: 'ChatType',
  5: 'CHAT_plProdCode',
  6: 'CHAT_clProdCode',
  7: 'CHAT_pageName',
  8: 'CHAT_autoQcn',
  9: 'CHAT_offerType',
  10: 'CHAT_affinityId',
  11: 'CHAT_ipAddress',
  12: 'CHAT_appEnvironment',
  13: 'displayForm',
  // 14: 'mdivqueue',
  // 15: 'mdiMainSkill',
  // 16: 'mdiStateSkill',
  17: 'CHAT_browserVersion',
  // 18: 'Oracle ContactUs',
  // 19: 'Oracle FAQ Prod/Cat',
  20: 'CHAT_producerCode',
  // 21: 'FormID',
  22: 'CHAT_claimNumber',
  23: 'CIAMSession',
  26: 'CHAT_department',
  27: 'CHAT_homeQcn',
  28: 'CHAT_supportTopic',
  33: 'CHAT_obscUserId',
  34: 'CHAT_aaId',
  35: 'CHAT_reasonForCall',
  37: 'CHAT_brixPolicyNumber',
  38: 'CHAT_thawUserId',
  39: 'CHAT_thawPolicyNumber',
  40: 'CHAT_organization',
  41: 'CHAT_appName',
  42: 'CHAT_zipCode',
  43: 'CHAT_policyIds',
  44: 'CHAT_billingIds',
  45: 'CHAT_agentId',
  51: 'CHAT_businessName',
  52: 'CHAT_phoneNumber',
  53: 'CHAT_nbvProducerCode',
  54: 'CHAT_agencyName',
  55: 'CHAT_regionCode',
  56: 'CHAT_quoteType',
  57: 'CHAT_qcnInflight',
  58: 'CHAT_qcnOriginal',
  59: 'CHAT_gbCaseId',
  61: 'CHAT_insuredId',
  62: 'CHAT_higempId',
  63: 'CHAT_classId',
  64: 'CHAT_ntId',
  65: 'CHAT_siteName',
  66: 'CHAT_reverseBundle',
  67: 'CHAT_dwellingType',
  68: 'CHAT_sourceCode',
  69: 'CHAT_daCookieOne',
  70: 'CHAT_daCookieTwo',
  71: 'CHAT_c2vRuleDesc',
  72: 'CHAT_plmScore',
  73: 'CHAT_customerFileNumber',
  74: 'CHAT_lineOfBusiness',
  75: 'CHAT_shuntReason',
  76: 'CHAT_transactionDetails',
  77: 'CHAT_quoteNumber',
  78: 'CHAT_errorMsg',
  79: 'CHAT_policyNumbers',
  80: 'CHAT_policyTerm',
  83: 'CHAT_vehicleYear',
  84: 'CHAT_vehicleMake',
  85: 'CHAT_vehicleModel',
  86: 'CHAT_eventTypeVisitor',
  87: 'CHAT_eventIdVisitor',
  88: 'CHAT_issuedPremium',
  89: 'CHAT_issuedMonthlyPremium',
  90: 'CHAT_quotedPremium',
  91: 'CHAT_quotedMonthlyPremium',
  92: 'CHAT_difQuotedVsIssued',
  93: 'CHAT_difQuotedVsIssuedMonthly',
  94: 'CHAT_stateInteraction',
  95: 'CHAT_underwriterName',
  96: 'CHAT_estimatedPremiumSize',
  97: 'CHAT_underwriterEmail',
  99: 'c2e_lob',
  100: 'BI_SC_Web_Questions',
  101: 'BI_SC_Policy_Billing',
  102: 'BI_SC_COI',
  103: 'BI_SC_Audit',
  104: 'Technical_Support_-_AIS',
  105: 'PL_Agency_Service_-_Home',
  106: 'BI_Service',
  107: 'BI_Audit',
  108: 'Download_AMS_Support_-_AIS',
  109: 'PL_Service',
  110: 'PL_Sales_-_Auto',
  111: 'PL_Sales_-_Home',
  112: 'SC_Direct_Sales'
};