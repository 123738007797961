import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { tap, switchMap, map, catchError } from 'rxjs/operators';

import * as ChatActions from '../actions/chat.actions';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { MessagingHandlerService } from '../../services/messaging-handler.service';
/**
 * NgRX Effects for Chat
 *
 * @export
 * @class ChatEffects
 */
@Injectable()
export class ChatEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private messageHandlerService: MessagingHandlerService
  ) {}

  /**
   * Sets activeChat to true on activeChatTrue action
   */
  setActiveChatTrue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatActions.activeChatTrue),
        tap(() => {
          sessionStorage['activeChat'] = true;
        })
      ),
    { dispatch: false }
  );

  /**
   * Sets activeChat to false on activeChatFalse action
   */
  setActiveChatFalse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatActions.activeChatFalse),
        tap(() => {
          sessionStorage['activeChat'] = false;
        })
      ),
    { dispatch: false }
  );

  getParticipantId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatActions.getParticipantId),
        switchMap(() => {
          return this.messageHandlerService.getParticipantId().pipe(
            map((res) => {
              res.forEach((participantId) => {
                this.messageHandlerService.getAgentName(participantId);
              });
              setTimeout(() => {
              this.messageHandlerService.postMedalliaSurvey();
              }, 1500);
            }),
            catchError((error) => {
              console.log(error);
              this.messageHandlerService.postMedalliaSurvey();
              return error;
            })
          );
        })
      ),
    { dispatch: false }
  );

  setSessionAttributes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatActions.setSessionAttributes),
        tap(() => {
          this.messageHandlerService.setSessionAttributes();
        })
      ),
    { dispatch: false }
  );
}
