<div class="c2e-content">
  <div class="c2e-connected-label">
    <div class="c2e-thankyou">
      <div id="thankYou" *ngIf="(callbackPostScreen$ | async) === 'thankYou'">
        <!-- <img src="assets/imgs/thankYou.svg" /> -->
        <div [inlineSVG]="'/assets/imgs/thankYou.svg'"></div>
        <br />
        <span class="c2e-msg-thankyou">Thank You!</span> <br /><br />
        <span *ngIf="callbackNumber$ | async"
          >We'll call you at<br /><br />
          <strong>{{ callbackNumber$ | async | phoneFormat }}</strong></span
        >
      </div>
      <div id="findingAgent" *ngIf="(callbackPostScreen$ | async) === 'findingAgent'">
        <!-- <img src="assets/imgs/findingAgent.svg" /> -->
        <div [inlineSVG]="'/assets/imgs/findingAgent.svg'"></div>
        <br /><span class="c2e-msg-searching">Finding your agent</span
        ><span class="c2e-responding"
          ><span class="c2e-dot"></span><span class="c2e-dot"></span><span class="c2e-dot"></span
        ></span>
        <br /><br />
        <span *ngIf="callbackNumber$ | async">
          We'll call you at<br />
          <strong>{{ callbackNumber$ | async | phoneFormat }}</strong></span
        >
      </div>
      <div id="connected" *ngIf="(callbackPostScreen$ | async) === 'connected'">
        <div [inlineSVG]="'/assets/imgs/connected.svg'"></div>
        <!-- <img src="assets/imgs/connected.svg" /> -->
        <br />
        Have a good call.
        <br /><br />
        Returning to Contact page.
      </div>
      <div id="cantConnect" class="c2c-unavailable" *ngIf="(callbackPostScreen$ | async) === 'cannotConnect'">
        <!-- <img src="assets/imgs/cannotConnect.svg" /> -->
        <div [inlineSVG]="'/assets/imgs/cannotConnect.svg'"></div>
        <br />
        Sorry… <br /><br />
        We cannot connect<br />
        your call right now.
      </div>
      <div id="cancelled" *ngIf="(callbackPostScreen$ | async) === 'cancelled'">
        <!-- <img src="assets/imgs/cancelled.svg" /> -->
        <div [inlineSVG]="'/assets/imgs/cancelled.svg'"></div>
        <br />
        Cancelled <br /><br />
        Your callback <br />
        has been cancelled.
      </div>
    </div>
  </div>
  <div
    id="c2e-cannot-cancel"
    *ngIf="
      !(cancellable$ | async) &&
      ((callbackPostScreen$ | async) === 'findingAgent' || (callbackPostScreen$ | async) === 'thankYou')
    "
  >
    <p>
      <br />
      Your callback is in progress.<br />
      If you want to cancel the<br />callback, press 9 after<br />
      you answer the call.
    </p>
  </div>
  <div
    *ngIf="
      ((callbackPostScreen$ | async) === 'findingAgent' || (callbackPostScreen$ | async) === 'thankYou') &&
      (cancellable$ | async) === true
    "
    class="c2e-form-fields"
  >
    <button
      (click)="cancelCallback()"
      type="button"
      name="hangup"
      id="c2e-hangup"
      class="btn btn-secondary-paired btn-lg btn-block"
      value="Cancel Callback"
    >
      Cancel Callback
    </button>
  </div>

  <div
    *ngIf="
      (callbackPostScreen$ | async) === 'cannotConnect' ||
      (callbackPostScreen$ | async) === 'cancelled' ||
      (((callbackPostScreen$ | async) === 'findingAgent' || (callbackPostScreen$ | async) === 'thankYou') &&
        !(cancellable$ | async))
    "
    class="c2e-form-fields"
  >
    <button
      (click)="returnToContactUs()"
      type="button"
      name="goBack"
      id="c2e-go-back"
      class="btn btn-secondary-paired btn-lg btn-block"
      value="OK"
    >
      Ok
    </button>
  </div>
</div>
