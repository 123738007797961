import { Component } from '@angular/core';
/**
 * Chat Loading Spinner Component
 *
 * @export
 * @class SpinnerComponent
 */
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {}
