import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { QuestionBase } from './cbform-questions-base';

@Injectable()
export class CBQuestionControlService {
  constructor() {}

  toFormGroup(questions: QuestionBase<string>[]) {
    const group: any = {};

    questions.forEach((question) => {
      const validator: ValidatorFn[] = question.required ? [Validators.required] : [];
      switch (question.validator) {
        case 'email':
          validator.push(Validators.email);
          break;
        case 'phoneLength':
          validator.push(this.createPhoneNumberValidator());
          break;
        default:
          break;
      }
      group[question.key] =
        validator.length > 0 ? new FormControl(question.value || '', validator) : new FormControl(question.value || '');
    });
    return new FormGroup(group);
  }

  createPhoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      const validLength = this.valPhoneLength(value);

      return !validLength ? { validLength: { value: control.value } } : null;
    };
  }

  /**
   * Helper method to validate phone number is ten digits long
   *
   * @param {string} phone
   * @return {*}  {(boolean | null)}
   */
  valPhoneLength(phone: string): boolean | null {
    if (!phone) {
      return null;
    }
    const re = /[a-zA-Z ,.*&^%$#@!~`/?"';:\[\]\{\}\\|+_\-=()]/g;
    const cleanedNum = phone.replace(re, '');
    if (cleanedNum && cleanedNum.length === 10) {
      return true;
    } else {
      return false;
    }
  }
}
