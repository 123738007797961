<div [formGroup]="form">
  <div [ngSwitch]="question.controlType">
    <div
      *ngSwitchCase="'textbox'"
      class="c2e_input_group form-group has-feedback"
      [ngClass]="{ 'has-error has-danger': !isValid && isTouched }"
    >
      <label *ngSwitchCase="'textbox'" [attr.for]="question.key" class="label-text control-label">{{
        question.label
      }}</label>
      <span *ngIf="question.required" class="c2e_required"> *</span>
      <input (keyup)="validateDirectToCallback()" *ngSwitchCase="'textbox'" [formControlName]="question.key" [id]="question.key" [type]="question.type" />
      <div *ngIf="!isValid && isTouched">
        <span class="icon icon-attention form-control-feedback glyphicon-remove" aria-hidden="true"> </span>
        <div class="help-block with-errors">{{ question.errorMessage }}</div>
      </div>
    </div>

    <div *ngSwitchCase="'dropdown'" class="c2e_input_group form-group">
      <label *ngSwitchCase="'dropdown'" [attr.for]="question.key">{{ question.label }}</label>
      <select [id]="question.key" *ngSwitchCase="'dropdown'" [formControlName]="question.key">
        <option
          *ngFor="let opt of question.options; let i = index"
          [value]="opt.key"
          [disabled]="question.dropdownHasDefault && i === 0"
        >
          {{ opt.value }}
        </option>
      </select>
    </div>
  </div>
</div>
