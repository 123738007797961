import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { ContactService } from '../contact.service';
import * as fromActions from '../../store/actions/contact.actions';
import { QuestionBase } from './cbform-questions-base';
import { CBQuestionControlService } from './cbform-questions-control.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-callback-dynamic-form',
  templateUrl: './cbform-dynamic.component.html',
  styleUrls: ['../contact-callback.component.scss'],
  providers: [CBQuestionControlService],
})
export class CBDynamicFormComponent implements OnInit, OnDestroy {
  @Input() questions: QuestionBase<string>[] | null = [];
  form!: FormGroup;
  payLoad = '';

  constructor(
    private qcs: CBQuestionControlService,
    private contactService: ContactService,
    private store: Store<State>
  ) {}

  unsubscribe$: Subject<boolean> = new Subject();

  ngOnInit(): void {
    
    this.form = this.qcs.toFormGroup(this.questions as QuestionBase<string>[]);
    this.contactService.policyNumber$.pipe(takeUntil(this.unsubscribe$)).subscribe((policyNumber: any) => {
      if (this.form.contains('policyNumber')) {
        this.form.patchValue({ policyNumber: policyNumber });
      }
    });
  }

  onSubmit(): void {
    this.payLoad = JSON.stringify(this.form.getRawValue());
    sessionStorage['c2c_reason'] = this.form.get('subject').value;
    sessionStorage['c2c_callbackNumber'] = this.cleanPhoneNumber(this.form.get('callbackNumber').value);
    if (this.form.get('policyNumber')?.value) {
      sessionStorage['c2c_policyNumbr'] = this.form.get('policyNumber').value;
    } else if (sessionStorage['CHAT_policyNumbers']) {
      sessionStorage['c2c_policyNumbr'] = sessionStorage['CHAT_policyNumbers'];
    } else if (sessionStorage['CHAT_policyIds']) {
      sessionStorage['c2c_policyNumbr'] = sessionStorage['CHAT_policyIds'];
    }
    this.store.dispatch(fromActions.setCallbackScreen({ screen: 'thankYou' }));
    this.store.dispatch(fromActions.setContactVisibleComponent({ component: 'postCallbackForm' }));
    this.form.reset();
    if (sessionStorage.getItem('c2e_callBackVQ') != undefined) {
      window.dispatchEvent(
        new CustomEvent('InitiateCallbackRequest', {
          detail: {
            queue: this.contactService.generateFullVqName(sessionStorage.getItem('c2e_callBackVQ')),
            brand: '',
          },
        })
      );
      sessionStorage.removeItem('c2e_callBackVQ');
    } else {
      window.dispatchEvent(
        new CustomEvent('InitiateCallbackRequest', {
          detail: {
            queue: this.contactService.callbackVQ,
            brand: this.contactService.getCallbackBrandId(this.contactService.callbackVQ),
          },
        })
      );
    }
  }

  cancel(): void {
    this.contactService.returnToContactUs();
    (document.getElementById('subject') as HTMLInputElement).disabled = false;
    (document.getElementById('subject') as HTMLInputElement).value = '';
    (document.getElementById('c2e-submit') as HTMLButtonElement).disabled = true
  }

  /**
   * Helper method to clean phone number
   * @param {string} num
   * @returns {string}
   */
  cleanPhoneNumber(num: string): string {
    const re = /[a-zA-Z ,.*&^%$#@!~`/?"';:\[\]\{\}\\|+_\-=()]/g;
    const cleanedNum = num.replace(re, '');
    return cleanedNum;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
