import { Pipe, PipeTransform } from '@angular/core';

/**
 * Formats phone number as (xxx) xxx-xxxx
 *
 * @export
 * @class PhoneFormatPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'phoneFormat',
})
export class PhoneFormatPipe implements PipeTransform {
  transform(phone): string {
    const output = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
    return output;
  }
}
