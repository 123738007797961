import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store/app-state';
import { Observable, Subject } from 'rxjs';
import * as fromChatActions from '../store/actions/chat.actions';
import {
  getActiveChatStatus,
  getLoadingStatus,
  getUnreadMessageCount,
  getActiveChatWindowStatus,
  getErrorStatus
} from '../store/selectors/chat.selectors';
import { takeUntil } from 'rxjs/operators';

/**
 * Chat component: Contains Chat Form, Chat Window, and Loading Spinner
 */
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  /**
   * Active Chat status (boolean)
   */
  activeChat$;

  /**
   * Validate Purpose: Subscribed value of activeChat$
   *
   */
  activeChat;

  /**
   * Display Chat loading image (boolean)
   */
  loading$;
  /**
   * Validate Purpose: Subscribed value of loading$
   *
   */
  loading;
  /**
   * Display error message when an error occurred between the client and the server(Genesys).
   */
  
  showError$;
  /**
   * Validate Purpose: Subscribed value of showError$
   *
   */
  showError;
  /**
   * Number of unread messages
   *
   * @type {Observable<number>}
   */
  unreadMsgCount$: Observable<number>;

  /**
   * Active tab is Chat (boolean)
   */
  activeChatWindow$;
  /**
   * Validate Purpose: Subscribed value of activeChatWindow$
   *
   */
  activeChatWindow;

   /**
   * Used to unsubscribe from subscriptions on ngDestroy
   * @type {Subject<boolean>}
   */
   unsubscribe$: Subject<boolean> = new Subject();

  constructor(private store: Store<State>) {
    this.unreadMsgCount$ = this.store.select(getUnreadMessageCount);
    this.activeChat$ = this.store.select(getActiveChatStatus);
    this.activeChat$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.activeChat = data;
    });
    this.loading$ = this.store.select(getLoadingStatus);
    this.loading$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.loading = data;
    });
    this.showError$ = this.store.select(getErrorStatus);
    this.showError$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.showError = data;
    });
    this.activeChatWindow$ = this.store.select(getActiveChatWindowStatus);
    this.activeChatWindow$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.activeChatWindow = data;
    });

    window.addEventListener(
      'ValidChatFormSubmitted',
      () => {
        if (sessionStorage['activeChat'] !== 'true') this.store.dispatch(fromChatActions.isLoading());
      },
      false
    );
    window.addEventListener(
      'WebChatStarted',
      () => {
        this.store.dispatch(fromChatActions.isNotLoading());
        this.store.dispatch(fromChatActions.activeChatTrue());
        this.store.dispatch(fromChatActions.activeChatWindowTrue());
        this.store.dispatch(fromChatActions.hideError());
      },
      false
    );
    window.addEventListener(
      'ChatEnded',
      () => {
        this.store.dispatch(fromChatActions.activeChatFalse());
      },
      false
    );
    window.addEventListener(
      'ChatClosed',
      () => {
        this.store.dispatch(fromChatActions.isNotLoading());
        this.store.dispatch(fromChatActions.activeChatFalse());
        this.store.dispatch(fromChatActions.hideError());
      },
      false
    );
    window.addEventListener('ExitChatWindow', () => {
      this.store.dispatch(fromChatActions.activeChatWindowFalse());
    });
    window.addEventListener('validMedalliaExists', () => {
      this.store.dispatch(fromChatActions.setSessionAttributes());
    });
    window.addEventListener('fireMedalliaSurvey', () => {
      this.store.dispatch(fromChatActions.getParticipantId());
    });
    window.addEventListener('handleError', () => {
      this.store.dispatch(fromChatActions.showError());
    });
  }
}
