<div class="transcript-wrapper">
  <div id="endChatAlert" class="modal hidden">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p>Are you sure you want to end the chat?</p>
          <button type="button" class="btn btn-primary btn-confirm">Yes</button>
          <button type="button" class="btn btn-secondary-paired btn-cancel">No</button>
        </div>
      </div>
    </div>
  </div>
  <div id="chatTopControlBar" class="cx-button-group cx-buttons-window-control">
    <button class="cx-icon cx-close" aria-label="Live Chat Close">End Chat</button>
  </div>
  <div id="conversation" tabindex="0"></div>
  <div id="hig_ChatAgentResponding">
    <span id="hig_AgentResponding" class="rn_Hidden">
      <span class="dot"></span>
      <span class="dot"></span>
      <span class="dot"></span>
    </span>
  </div>
  <div id="hig_ChatCustomFooter" class="hidden">
    <div id="rn_ChatPostMessage" class="rn_ChatPostMessage">
      <div id="suggestedSearch" hidden style="z-index: 10;"></div>
      <input type="text" id="wisdom" aria-label="Type your message" size="30" placeholder="Type your message" value="" #chatInput/>
    </div>
    <div id="hig_ChatCustomFooterSendContainer" class="hig_ChatCustomFooterSendContainer">
      <span
        id="rn_ChatSendButton"
        class="rn_ChatSendButton rn_ChatSendButtonShown"
        title="Send the message to the agent"
      >
        <button id="rn_ChatSendButton_Button">Send</button>
      </span>
    </div>
  </div>
</div>
