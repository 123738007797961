<!-- <p>chat-form works!</p> -->
<div [hidden]="hideForm===true">
  <form (ngSubmit)="onSubmit()" [formGroup]="chatForm">
    <div class="c2e_form_wrapper">
      <div class="info-panel">
        <!-- Subject field -->
        <div
          class="c2e_input_group form-group has-feedback active"
          [ngClass]="{
            'has-error has-danger': subject.invalid && subject.touched
          }"
        >
          <label class="label-text control-label" for="subject">
            How can we help you today?
            <span class="c2e_required"> *</span>
          </label>
          <input type="text" id="subject" class="form-control" required formControlName="subject" />
          <div *ngIf="subject.invalid && subject.touched">
            <span class="icon icon-attention form-control-feedback glyphicon-remove" aria-hidden="true"> </span>
            <div class="help-block with-errors">Please enter a subject.</div>
          </div>
        </div>
        <!-- First Name -->
        <div
          class="c2e_input_group form-group has-feedback"
          [ngClass]="{
            'has-error has-danger': firstName.invalid && firstName.touched
          }"
        >
          <label class="label-text control-label" for="firstName">
            First Name
            <span class="c2e_required"> *</span>
          </label>
          <input type="text" id="firstName" class="form-control" required formControlName="firstName" />
          <div *ngIf="firstName.invalid && firstName.touched">
            <span class="icon icon-attention form-control-feedback glyphicon-remove" aria-hidden="true"></span>
            <div class="help-block with-errors">Please enter your first name.</div>
          </div>
        </div>
        <!-- Last Name -->
        <div
          class="c2e_input_group form-group has-feedback"
          [ngClass]="{
            'has-error has-danger': lastName.invalid && lastName.touched
          }"
        >
          <label class="label-text control-label" for="lastName">
            Last Name
            <span class="c2e_required"> *</span>
          </label>
          <input type="text" id="lastName" class="form-control" required formControlName="lastName" />
          <div *ngIf="lastName.invalid && lastName.touched">
            <span class="icon icon-attention form-control-feedback glyphicon-remove" aria-hidden="true"></span>
            <div class="help-block with-errors">Please enter your last name.</div>
          </div>
        </div>
        <!-- Email Address -->
        <div
          class="c2e_input_group form-group has-feedback"
          [ngClass]="{
            'has-error has-danger': email.invalid && email.touched
          }"
        >
          <label class="label-text control-label" for="email">
            Email Address
            <span class="c2e_required"> *</span>
          </label>
          <input type="email" id="email" class="form-control" name="email" required formControlName="email" pattern=".*@\w+([-.]\w+)*\.\w{2,}.*" />
          <div *ngIf="email.errors && email.touched">
            <span class="icon icon-attention form-control-feedback glyphicon-remove" aria-hidden="true"></span>
            <div class="help-block with-errors">
              <div *ngIf="email.errors && email.touched" class="is-invalid">
                <div *ngIf="email.errors.required">This field is required</div>
                <div *ngIf="email.errors.email || email.errors.pattern">That doesn’t look quite right. Please enter your email in this format: name@domain.com.</div>
              </div>
            </div>
          </div>
        </div>
        <!--- addtional fields-->
        <div formArrayName="fields" *ngIf="chatForm.get('fields')['controls'].length >0">
          <div *ngFor="let field of chatForm.get('fields')['controls']; let j=index">
            <div [formGroupName]="j">
              <div
              class="c2e_input_group form-group has-feedback"
              [ngClass]="{
                'has-error has-danger': field.controls.fieldValue.touched && field.controls.fieldValue.errors && field.controls.fieldValue.errors.required
              }"
            >
              <label class="label-text control-label" for="fieldValue">
                {{field.value.fieldLabel}}
                <span *ngIf="field.value.fieldRequired" class="c2e_required"> *</span>
              </label>
              <span *ngIf="!field.value.fieldRequired">
                <input type="text" class="form-control"   formControlName="fieldValue"   id="fieldId"/>
              </span>

              <span *ngIf="field.value.fieldRequired">
              <input type="text" class="form-control"  formControlName="fieldValue"   id="fieldId" required/>
              <div *ngIf="field.controls.fieldValue.touched && field.controls.fieldValue.errors && field.controls.fieldValue.errors.required">
                <span class="icon icon-attention form-control-feedback glyphicon-remove" aria-hidden="true"></span>
                <div class="help-block with-errors">Please enter your {{field.value.fieldLabel}}.</div>
              </div>
            </span>
            </div>
            </div>
          </div>
        </div>
    

      </div>
    </div>

    <div class="footerForm">
      <div class="formSubmit c2e_formSubmit">
        <button [disabled]="chatForm.invalid" type="submit" class="displayButton">Continue</button>
      </div>
    </div>
  </form>
</div>
