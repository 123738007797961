import { Injectable } from '@angular/core';
import { QuestionBase } from './cbform-questions-base';
import { Observable, of } from 'rxjs';
import * as fromMappings from './callback-mappings';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  getQuestions(): Observable<QuestionBase<any>[]> {
    let questions: QuestionBase<string>[];
    if (sessionStorage['CHAT_appName'] === 'EBC') {
      questions = fromMappings.FORM_EBC_FIELDS;
    } else questions = fromMappings.FORM_DEFAULT_FIELDS;
    return of(questions.sort((a, b) => a.order - b.order));
  }
}
