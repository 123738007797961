import { createAction, props } from '@ngrx/store';

/**
 * DEPRECATED?: Validate
 */
export const toggleActiveChat = createAction('[Chat] Toggle Active Chat Status');

/**
 * Chat is active
 */
export const activeChatTrue = createAction('[Chat] Active Chat True');

/**
 *  Chat is not active
 */
export const activeChatFalse = createAction('[Chat] Active Chat False');

/**
 * Clears the count of the current unread messages
 */
export const clearUnreadMessages = createAction('[Chat] Clear Unread Message Count');

/**
 * Increases count of unread messages
 */
export const incrementUnreadMessages = createAction('[Chat] Increment Unread Messages');

/**
 * Valid chat form has been submitted and chat is loading
 */
export const isLoading = createAction('[Chat] Loading');

/**
 * Chat is either active (having connected to agent) or terminated
 */
export const isNotLoading = createAction('[Chat] Loading Complete');


/**
 * Show Error message inside EW if error occurred between the client and the server.
 */
 export const showError = createAction('[Chat] Show Error');

 /**
  * Hide the error message inside EW for success api call
  */
 export const hideError = createAction('[Chat] Hide Error');

/**
 * VALIDATE: Prevents further Chat userData from loading
 */
export const doNotLoadDataIsTrue = createAction('[Chat] Set doNotLoadData to true');

/**
 * VALIDATE: Allows futher Chat userData to be loaded
 */
export const doNotLoadDataIsFalse = createAction('[Chat] Set doNotLoadData to false');

/**
 * DEPRECATED?: Validate
 */
export const toggleActiveChatWindow = createAction('[Chat] Toggle Active Chat Window Status');

/**
 * Active Tab is Chat
 */
export const activeChatWindowTrue = createAction('[Chat] Active Chat Window True');

/**
 * Active Tab is not chat
 */
export const activeChatWindowFalse = createAction('[Chat] Active Chat Window False');

export const getParticipantId = createAction('[Chat] Get Participant Id');

export const setSessionAttributes = createAction('[Chat] Set Session Attributes');

export const autosubmitProactiveChatTrue = createAction('[Chat] Autosubmit Proactive Chat True');

export const autosubmitProactiveChatFalse = createAction('[Chat] Autosubmit Proactive Chat False');
