import { Component } from '@angular/core';
/**
 * Main App Root
 *
 * Template contains router-outlet
 *
 * @date 2021-06-29
 * @export
 * @class AppComponent
 */

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {}
