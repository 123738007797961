import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { State } from '../store/reducers';
import { Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';

import {
  getAuthorizationToken,
  getCallbackEligibilityState,
  getContactUsId,
  getCallbackPostScreen,
  getContactVisibleComponent,
} from '../store/selectors/contact.selectors';

/**
 * Contact tab component
 *
 * @export
 * @class ContactComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit, OnDestroy {
  constructor(private sanitizer: DomSanitizer, private store: Store<State>) {}

  /**
   * OKA Article ID used to get Contact page text
   *
   * @type {Observable<string>}
   * @memberof ContactComponent
   */
  contactUsId$: Observable<string>;

  /**
   * OKA Article ID (subscribed value)
   *
   * @memberof ContactComponent
   */
  contactUsId;

  /**
   * Callback should be displayed (per data from host site)
   *
   * @type {Observable<boolean>}
   * @memberof ContactComponent
   */
  callbackEligibility$: Observable<boolean>;

  /**
   * View to display on Contact tab
   *
   * Options: contact | callbackForm | postCallbackForm
   *
   * @type {Observable<string>}
   * @memberof ContactComponent
   */
  contactVisibleComponent$: Observable<string>;

  /**
   * Screen to display after Callback request is submitted
   *
   * @type {Observable<string>}
   * @memberof ContactComponent
   */
  callbackPostScreen$: Observable<string>;

  authToken$;
  authToken;

  /**
   * Used to unsubscribe from subscriptions on ngDestroy
   *
   * @type {Subject<boolean>}
   */
  unsubscribe$: Subject<boolean> = new Subject();

  /**
   * Subscribes to various Store values
   */
  ngOnInit(): void {
    this.contactUsId$ = this.store.select(getContactUsId);
    this.contactUsId$.pipe(takeUntil(this.unsubscribe$)).subscribe((id) => (this.contactUsId = id));
    this.authToken$ = this.store.select(getAuthorizationToken);
    this.authToken$.pipe(takeUntil(this.unsubscribe$)).subscribe((token) => (this.authToken = token));
    this.callbackEligibility$ = this.store.select(getCallbackEligibilityState);
    this.callbackPostScreen$ = this.store.select(getCallbackPostScreen);
    this.contactVisibleComponent$ = this.store.select(getContactVisibleComponent);
  }

  /**
   * Angular ngOnDestroy
   *
   * @memberof ContactComponent
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
