import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
/**
 * Sanitizes URL to avoid XSS issues
 *
 * Used by FAQ component
 *
 * @export
 * @class SafePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  /**
   * Transforms URL
   *
   * @param {*} url
   * @returns
   * @memberof SafePipe
   */
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
