import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { setContactUsId } from '../actions/contact.actions';
import { changeTabs, ewOpen, ewClose, setContactUsURL, setFAQsURL, changeTabsNoPost } from '../actions/tabs.actions';
import * as AppState from '../app-state';

/**
 * Key for Store tabs feature selector
 */
export const tabsFeatureKey = 'tabs';

/**
 * Interface adding TabsState to main App state
 *
 * @export
 * @interface State
 * @extends {AppState.State}
 */
export interface State extends AppState.State {
  /**
   * NgRX Tabs State
   *
   * @type {TabsState}
   */
  tabs: TabsState;
}

/**
 * Interface for Tabs state
 *
 * @export
 * @interface TabsState
 */
export interface TabsState {
  /**
   * Current active tab
   *
   * @type {string}
   */
  activeTab: string;

  /**
   * DEPRECATED?: Validate this versus ChatState loading
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * EW is open or closed
   *
   * @type {boolean}
   */
  ewOpen?: boolean;

  /**
   * DEPRECATED: No longer in use
   *
   * @type {string}
   */
  contactUsUrl: string;

  /**
   * FAQ Url
   *
   * @type {string}
   */
  faqsUrl: string;
}

/**
 * Initial TabsState
 */
export const initialState: TabsState = {
  activeTab: 'chat',
  loading: false,
  ewOpen: false,
  contactUsUrl: '',
  faqsUrl: '',
};

/**
 * NgRX Tabs reducer
 */

export const reducer = createReducer(
  initialState,
  on(changeTabs, (state, { id }) => ({ ...state, activeTab: id })),
  on(changeTabsNoPost, (state, { id }) => ({ ...state, activeTab: id })),
  on(ewOpen, (state) => ({ ...state, ewOpen: true })),
  on(ewClose, (state) => ({ ...state, ewOpen: false })),
  on(setContactUsURL, (state, { url }) => ({ ...state, contactUsUrl: url })),
  on(setFAQsURL, (state, { url }) => ({ ...state, faqsUrl: url }))
);
