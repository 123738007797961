import { createSelector } from '@ngrx/store';
import { selectGeneralState } from '../reducers';
import { GeneralState } from '../reducers/general.reducer';

/**
 * Get host app name
 */

export const getAppName = createSelector(selectGeneralState, (state: GeneralState) => state.appName);
export const getEnvironmentType = createSelector(selectGeneralState, (state: GeneralState) => state.environmentType);
