import { createReducer, on } from '@ngrx/store';
import { ContactUsData, GenesysEwtVqData, ScoringModelPayload } from '../../contact/contact-us-data.component';
import * as fromActions from '../actions/contact.actions';
import * as AppState from '../app-state';
import { getCallbackEwtObject } from '../selectors/contact.selectors';

/**
 * Key for Store contact feature selector
 */
export const contactFeatureKey = 'contact';

/**
 * Interface adding ContactState to main App state
 *
 * @export
 * @interface State
 * @extends {AppState.State}
 */
export interface State extends AppState.State {
  /**
   * NgRX Contact State
   *
   * @type {ContactState}
   */
  contact: ContactState;
}

/**
 * Interface for Contact Us page and callback store data
 *
 * @export
 * @interface ContactState
 */
export interface ContactState {
  /**
   * Genesys Callback authorization token
   *
   * @type {string}
   */
  authorizationToken: string;

  /**
   * OKA Contact Us ID
   *
   * @type {string}
   */
  contactUsId: string;

  /**
   * Error message receiving Contact Us data from OKA
   */
  contactUsError: any;

  /**
   * Active callback is in process
   *
   * @type {boolean}
   */
  activeCallback: boolean;

  /**
   * Phone number used by active callback
   *
   * @type {string}
   */
  activeCallbackNumber: string;

  /**
   * Callback request form should be visible
   */
  showCallbackForm: boolean;

  /**
   * Contact Us page view
   *
   * @type {string}
   */
  contactVisibleComponent: string;

  contactUsArray: Array<ContactUsData>;

  contactUsObject: any;

  cbEwtArray?: Array<GenesysEwtVqData> | null;

  callbackEwtObject?: any;

  /**
   * Page is eligible for callback
   */
  callbackEligible: boolean;

  /**
   * Error message when attempting callback
   *
   * @type {boolean}
   */
  callbackRequestError: string;

  /**
   * Which post-callback request screen should be visible
   *
   * Options: thankYou | findingAgent | connected | cannotConnect | cancelled
   * @type {string}
   */
  callbackPostScreen: string;

  /**
   * Genesys callback ID
   *
   * @type {string}
   */
  callbackId: string;
  /**
   * Callback can be cancelled
   *
   * @type {boolean}
   */
  callbackCancellable: boolean;

  /**
   * Callback estimated wait time in seconds
   *
   * Used by cb_extension.js to determine display
   *
   * @type {number}
   */
  ewt?: number;

  callbackVQ: string;

  /**
   * Policy number used for callback and elsewhere
   *
   * @type {string}
   */
  policyNumber?: string;

  /**
   * sets true if direct to callback is triggered.
   *
   * @type {boolean}
   */
  directToCallback: boolean;

  payload?: ScoringModelPayload;
  /**
   * This will be a number in the range of 0 to 100.  This priority will then be used in the routing logic
   * For eg: for default priority, 10 = low , 50 = medium and 100 = high
   * @type {number}
   */
  priority?: number;
}
/**
 * Initial ContactState
 */
export const initialState: ContactState = {
  authorizationToken: '',
  contactUsId: '',
  contactUsError: '',
  activeCallback: false,
  activeCallbackNumber: '',
  showCallbackForm: false,
  contactVisibleComponent: 'contact',
  contactUsArray: [],
  contactUsObject: {},
  callbackEligible: false,
  callbackRequestError: '',
  callbackPostScreen: '',
  callbackId: '',
  callbackCancellable: true,
  callbackVQ: '',
  callbackEwtObject: {},
  directToCallback: false
};

/**
 * NgRX Contact reducer
 */
export const reducer = createReducer<ContactState>(
  initialState,
  on(fromActions.setAuthorizationToken, (state, { token }) => ({ ...state, authorizationToken: token })),
  on(fromActions.setContactUsId, (state, { contactUsId }) => ({ ...state, contactUsId: contactUsId })),

  on(fromActions.loadContactUsFailure, (state, { error }) => ({
    ...state,
    contactUsError: error,
  })),
  on(fromActions.clearContactUsError, (state) => ({ ...state, contactUsError: '' })),
  on(fromActions.callbackEligible, (state) => ({ ...state, callbackEligible: true })),
  on(fromActions.callbackIneligible, (state) => ({ ...state, callbackEligible: false })),
  on(fromActions.activeCallbackIsTrue, (state, { callbackNumber }) => ({
    ...state,
    activeCallback: true,
    activeCallbackNumber: callbackNumber,
  })),
  on(fromActions.activeCallbackIsFalse, (state) => ({ ...state, activeCallback: false, activeCallbackNumber: '' })),

  on(fromActions.showCallbackForm, (state) => ({ ...state, showCallbackForm: true })),
  on(fromActions.hideCallbackForm, (state) => ({ ...state, showCallbackForm: false })),
  on(fromActions.setCallbackRequestError, (state, { error }) => ({ ...state, callbackRequestError: error })),
  on(fromActions.setCallbackScreen, (state, { screen }) => ({ ...state, callbackPostScreen: screen })),
  on(fromActions.setCallbackId, (state, { id }) => ({ ...state, callbackId: id })),
  on(fromActions.clearCallbackId, (state) => ({ ...state, callbackId: '' })),
  on(fromActions.callbackIsCancellable, (state) => ({ ...state, callbackCancellable: true })),
  on(fromActions.callbackIsNotCancellable, (state) => ({ ...state, callbackCancellable: false })),
  on(fromActions.setEWT, (state, { ewt }) => ({ ...state, ewt: ewt })),
  on(fromActions.clearEWT, (state) => ({ ...state, ewt: -1 })),
  on(fromActions.setContactVisibleComponent, (state, { component }) => ({
    ...state,
    contactVisibleComponent: component,
  })),
  on(fromActions.setCallbackVQ, (state, { vq }) => ({ ...state, callbackVQ: vq })),
  on(fromActions.setDirectToCallbackTrue, (state) => ({ ...state, directToCallback: true })),
  on(fromActions.setDirectToCallbackFalse, (state) => ({ ...state, directToCallback: false })),
  on(fromActions.setContactUsArray, (state, { array }) => ({ ...state, contactUsArray: array })),
  on(fromActions.setCallbackEwtArray, (state, { array }) => ({ ...state, cbEwtArray: array })),
  on(fromActions.setContactUsObject, (state, { object }) => ({ ...state, contactUsObject: object })),
  on(fromActions.setCallbackEwtObject, (state, { object }) => ({ ...state, callbackEwtObject: object })),
  on(fromActions.setPolicyNumber, (state: ContactState, { policyNumber }) => ({
    ...state,
    policyNumber: policyNumber,
  })),
  on(fromActions.setPriority, (state, { priority }) => ({ ...state, priority: priority })),
);
