import { createAction, props } from '@ngrx/store';

// export const changeTabs = createAction('[Tabs] Change Tabs', props<{ id: string }>());

/**
 * Change tabs
 *
 * See  {@link ../injectables/TabsEffects.html#getTab$|getTab$ Effect }
 */
export const changeTabs = createAction('[Tabs] Tab Changed', props<{ id: string }>());

/**
 * Change tabs for CHAT Only when user clicks X on outer frame when in active chat
 *
 * Necessary to prevent erroneous chat tab change event for data analytics
 */
export const changeTabsNoPost = createAction('[Tabs] Tab Changed - No Analytics Post', props<{ id: string }>());

/**
 * Triggers: EW is open
 */
export const ewOpen = createAction('[App] EW Open');

/**
 * Triggers: EW is closed
 */
export const ewClose = createAction('[App] EW Close');

/**
 * DEPRECATED: Sets URL for Contact tab
 */
export const setContactUsURL = createAction('[Tabs] Set ContactUsURL', props<{ url: string }>());

/**
 * Sets URL for FAQ tab
 */
export const setFAQsURL = createAction('[Tabs] Set FAQsURL', props<{ url: string }>());
