<div id="callbackContainer" class="c2e-callback-container container">
  <div id="callbackFormContainer">
    <div class="callback_content_header">Don't wait on the line &mdash; we'll call you!</div>
    <div class="c2e_content">
      <div class="c2e_svg c2e_svg_icon" [inlineSVG]="'/assets/imgs/clock.svg'"></div>
      <!--<div class="c2e-form-label" id="c2e-ewt-label">
        <span>Wait Time</span>
        <span class="c2e-responding"
          ><span class="c2e-dot"></span><span class="c2e-dot"></span><span class="c2e-dot"></span></span
        > {{ formattedEwt$ | async }}
      </div>-->
    </div>
    <app-callback-dynamic-form [questions]="questions$ | async"></app-callback-dynamic-form>
  </div>
  <div></div>
</div>
