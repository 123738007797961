<div id="contact-row-wrapper">
  <div class="contact-row" *ngFor="let row of rows$ | async">
    <h4 class="contact-us-row-header">{{ row.name }}</h4>

    <p class="contact-us-hours">
      {{ row.hoursOfOperation }}
      <span *ngIf="row.hoursOfOperation2"> <br />{{ row.hoursOfOperation2 }}</span>
    </p>

    <p class="contact-us-phone" *ngIf="row.phoneNumber !== ''">
      <a href="tel:{{ row.phoneNumber }}" target="_top"> {{ row.phoneNumber }}</a>
    </p>

    <span id="button-container" *ngIf="row.displayCallback">
      <button
        *ngIf="checkSkillName(row.genesysSkillDisplayName)"
        class="btn btn-secondary-paired btn-sm btn-block"
        (click)="initCallbackForm(row.genesysSkillVQ)"
      >
        <span>Request a Call</span>
      </button>
      <button *ngIf="!checkSkillName(row.genesysSkillDisplayName)" class="btn btn-secondary-paired btn-sm btn-block" disabled>
        We're sorry, but we currently don't have any phone reps available.
        <br />Please try again later.
      </button>
    </span>
  </div>
</div>