import { createSelector, Store } from '@ngrx/store';
import { selectChatState, State } from '../reducers';
import { ChatState } from '../reducers/chat.reducer';

/**
 * Get active chat status
 */

export const getActiveChatStatus = createSelector(selectChatState, (state: ChatState) => state.activeChat);

/**
 * Get Chat Unread Messages 
 */
export const getUnreadMessageCount = createSelector(selectChatState, (state: ChatState) => state.unreadMessageCount);

/**
 * Get chat loading status
 */
export const getLoadingStatus = createSelector(selectChatState, (state: ChatState) => state.loading);

/**
 * DETERMINE WHAT THIS DOES; is it to stop reloading of userData once form has been submitted?
 */
export const getDoNotLoadStatus = createSelector(selectChatState, (state: ChatState) => state.doNotLoadData);

/**
 * Determine whether/not Chat is active tab
 */
export const getActiveChatWindowStatus = createSelector(selectChatState, (state: ChatState) => state.activeChatWindow);

export const getProactiveAutosubmitStatus = createSelector(selectChatState, (state: ChatState) => state.autoSubmitProactiveChat);
export const getErrorStatus = createSelector(selectChatState, (state: ChatState) => state.showError);
