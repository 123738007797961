import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { ChatComponent } from './chat/chat.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';
import { UnreadMessagesService } from './services/unread-msgs.service';
import { MessagingHandlerService } from './services/messaging-handler.service';
import { CookieService } from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { ChatFormComponent } from './chat/chat-form/chat-form.component';

import { ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './spinner/spinner.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { reducers, metaReducers } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { TabsEffects } from './store/effects/tabs.effects';
import { ContactEffects } from './store/effects/contact.effects';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs/tabs.component';
import { SafePipe } from './services/safe.pipe';
import { ChatWindowComponent } from './chat/chat-window/chat-window.component';
import { ContactCallbackComponent } from './contact/contact-callback.component';
import { ContactCbPostComponent } from './contact/contact-cb-post.component';
import { PhoneFormatPipe } from './phone-format.pipe';
import { InlineSVGModule } from 'ng-inline-svg';
import { StandaloneComponent } from './standalone/standalone.component';
import { AppRoutingModule } from './app-routing.module';
import { ChatEffects } from './store/effects/chat.effects';
import { ByPassSecurityPipe } from './bypassHtmlSecurityPipe.pipe';
import { DynamicContactRowComponent } from './contact/dynamic-contact-row.component';
import { CBDynamicFormComponent } from './contact/callbackform/cbform-dynamic.component';
import { DynamicFormQuestionComponent } from './contact/callbackform/cbform-questions.component';
import { QuestionService } from './contact/callbackform/cbform-questions.service';
import { CBQuestionControlService } from './contact/callbackform/cbform-questions-control.service';
import { ErrorComponent } from './error/error.component';
import { SafeAsyncPipe } from './services/safe-async.pipe';

@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    FaqComponent,
    ContactComponent,
    ChatFormComponent,
    SpinnerComponent,
    TabsComponent,
    SafePipe,
    ChatWindowComponent,
    ContactCallbackComponent,
    ContactCbPostComponent,
    PhoneFormatPipe,
    StandaloneComponent,
    ByPassSecurityPipe,
    SafeAsyncPipe,
    DynamicContactRowComponent,
    CBDynamicFormComponent,
    DynamicFormQuestionComponent,
    ErrorComponent,
    // TabsBaseComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([TabsEffects, ContactEffects, ChatEffects]),
    CommonModule,
    InlineSVGModule.forRoot(),
    AppRoutingModule,

    // HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, { dataEncapsulation: false }),
  ],
  exports: [],
  providers: [UnreadMessagesService, MessagingHandlerService, CookieService, QuestionService, CBQuestionControlService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

declare global {
  interface Window {
    /**
     * Creates _genesys object on window
     *
     * @type {*}
     */
    _genesys: any;
  }
}
window._genesys = window._genesys || {};
