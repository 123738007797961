/* eslint-env es6 */
import { ChangeDetectorRef, Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as $ from 'jquery';
import { from, Observable, of, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { MessagingHandlerService } from '../../services/messaging-handler.service';
import { doNotLoadDataIsFalse, isLoading } from '../../store/actions/chat.actions';
import { getEWStatus, State, getActiveTab } from '../../store/reducers';
import { getDoNotLoadStatus, getProactiveAutosubmitStatus } from '../../store/selectors/chat.selectors';
import { getDirectToCallback } from '../../store/selectors/contact.selectors';
import { ChatForm, FormField } from './chat-form.model';
import { UAParser } from 'ua-parser-js';
@Component({
  selector: 'app-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.scss'],
  // styleUrls: ['../../../styles.scss'],
})
export class ChatFormComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Chat form FormGroup
   * @type {FormGroup}
   */
  chatForm: FormGroup;

  /**
   * Used to unsubscribe from subscriptions on ngDestroy
   * @type {Subject<boolean>}
   */
  unsubscribe$: Subject<boolean> = new Subject();
  shouldLoadData$;
  doNotLoadData$;

  directToCallBack$;

  triggerAutoSubmitOnTabChange: boolean;

  autosubmitProactiveChat$;

  triggerAutoSubmitForProactiveChat: boolean;


  /**
   * Validate Purpose: Subscribed value of doNotLoadData
   *
   */
  doNotLoadDataBool;

  /**
   * VALIDATE USAGE: Used with random genesysObject
   */
  widgetData;

  /**
   * Array of fields in Chat form
   */
  formfields = [];

  /**
   * Array of dynamic form fields
   * @type {FormField[]}
   */
  addedFields: FormField[] = [];

  /**
   *Creates an instance of ChatFormComponent.
   * @param {FormBuilder} fb
   * @param {MessagingHandlerService} messageHandlerService
   * @param {Store<State>} store
   * @param {ChangeDetectorRef} ref
   */
  /**
   * Hide Form (boolean)
   */
  displayForm: any;
  setDisplayFormVal: any;
  /**
   * Indicates whether/not Engagement Widget is open
   */
  ewOpenStatus$: Observable<boolean>;

  /**
   *
   * String value of ewOpenStatus$
   */
  ewOpenStatus: boolean;
   /**
   * Active tab (observable)
   *
   * @type {Observable<string>}
   */
  activeTab$: Observable<string>;

   /**
   * Current Tab name
   */
  tabName: string;

  isFormAutoSubmitted: boolean = false;

  hideForm: boolean;
  /**
   * Used to unsubscribe from subscriptions on ngDestroy
   *
   * @type {Subject<boolean>}
   */
  constructor(
    private fb: FormBuilder,
    private messageHandlerService: MessagingHandlerService,
    private store: Store<State>,
    private ref: ChangeDetectorRef
  ) {
    this.displayForm = 'true';
    this.widgetData = messageHandlerService.genesysObject;
    this.ewOpenStatus$ = this.store.select(getEWStatus);
  }

  ngAfterViewInit(): void {
    //resetting this value to prevent the phantom chats
    sessionStorage['ewMaximizedTriggered'] = 'false';
  }

  /**
   * Angular ngOnInit
   */
  ngOnInit(): void {
    this.doNotLoadData$ = this.store.select(getDoNotLoadStatus);
    this.directToCallBack$ = this.store.select(getDirectToCallback);
    this.directToCallBack$.pipe(takeUntil(this.unsubscribe$)).subscribe((data: boolean) => {
      this.triggerAutoSubmitOnTabChange = data;
    });
    this.activeTab$ = this.store.select(getActiveTab);
    this.doNotLoadData$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.doNotLoadDataBool = data;
    });
    this.chatForm = this.fb.group({
      subject: [this.storageValOrEmpty('subject'), { validators: [Validators.required], updateOn: 'blur' }],
      firstName: [this.storageValOrEmpty('firstName'), { validators: [Validators.required], updateOn: 'blur' }],
      lastName: [this.storageValOrEmpty('lastName'), { validators: [Validators.required], updateOn: 'blur' }],
      email: [
        this.storageValOrEmpty('email'),
        {
          validators: [Validators.required, Validators.email],
          // updateOn: 'blur',
        },
      ],
      fields: this.fb.array([]),
    });

    //Auto submit for standard chat
    this.ewOpenStatus$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.ewOpenStatus = data;
      if (this.ewOpenStatus === true && sessionStorage['ewMaximizedTriggered'] === 'true') {
        this.autoSubmitChat();
      }
    });

    // Autosubmit for proactive chat
    this.autosubmitProactiveChat$ = this.store.select(getProactiveAutosubmitStatus);
    this.autosubmitProactiveChat$.pipe(takeUntil(this.unsubscribe$)).subscribe((status) => {
      this.triggerAutoSubmitForProactiveChat = status;
      if (this.ewOpenStatus === true && this.triggerAutoSubmitForProactiveChat) {
        this.autoSubmitChat();
      }
    });

    // Auto submit for direct to call back when we change tab from contact to chat for the first time
    this.activeTab$.pipe(takeUntil(this.unsubscribe$)).subscribe((tab) => {
      this.tabName = tab;

      if (
        this.ewOpenStatus === true &&
        this.messageHandlerService.tabName === 'chat' &&
        !this.isFormAutoSubmitted &&
        this.triggerAutoSubmitOnTabChange
      ) {
        this.autoSubmitChat();
      }
    });

    //pre-populate the chat form
    this.chatForm.patchValue({
      subject: sessionStorage.getItem('subject'),
      firstName: sessionStorage.getItem('firstName'),
      lastName: sessionStorage.getItem('lastName'),
      email: sessionStorage.getItem('emailAddress'),
    });
    this.storageValCheckOrEmpty('formfields');
    this.messageHandlerService.formFieldData.pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
      if (data.fieldLabel && this.chatForm && this.chatForm.controls.hasOwnProperty('fields')) {
        this.addField(data);
      }
      this.ref.markForCheck();
    });
    if (this.doNotLoadDataBool != true) {
      this.shouldLoadData$ = this.messageHandlerService.shouldLoadData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data) => this.loadData(data));
    }
    for (const controlProperty in this.chatForm.controls) {
      if (this.chatForm.controls.hasOwnProperty(controlProperty)) {
        this.chatForm.controls[controlProperty].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
          this.formValidationCheck();
          if (controlProperty == 'fields' && result.length > 0) {
            sessionStorage.setItem('fields', 'true');
            sessionStorage.setItem('formfields', JSON.stringify(this.chatForm.get('fields').value));
            result.forEach((element: FormField) => {
              this.setValue(element.fieldId, element.fieldValue);
            });
          } else {
            this.setValue(controlProperty, result);
          }
        });
      }
    }
  }

  autoSubmitChat() {
    this.displayForm = JSON.parse(sessionStorage.getItem('displayForm'));
    if (this.displayForm === false) {
      this.hideForm = true;
      this.updateChatForm();
    } 
  }

  // Auto submit the form
  updateChatForm() {
    this.setDisplayFormVal = setInterval(() => {
      const _subject = sessionStorage.getItem('CHAT_pageTitle');
      const _firstname = sessionStorage.getItem('firstname') ?? '';
      const _lastname = sessionStorage.getItem('lastname') ?? '';
      const _email = sessionStorage.getItem('email') ?? '';
      if (this.displayForm === false && !this.isFormAutoSubmitted) {
        this.chatForm.patchValue({
          subject: _subject,
          firstName: _firstname,
          lastName: _lastname,
          email: _email,
        });
        this.onSubmit();
        this.isFormAutoSubmitted = true;
        this.clearTimeInt();
      } else {
        this.hideForm = false;
        this.clearTimeInt();
      }
    }, 500);
  }

  //for auto submit form
  clearTimeInt() {
    clearInterval(this.setDisplayFormVal);
  }
  /**
   * Triggered by onInit. If sessionStorage['formFields'] contains items, loop through them and add them to Angular form
   * @param key
   */
  storageValCheckOrEmpty(key): any {
    this.formfields = sessionStorage[key] ? JSON.parse(sessionStorage[key]) : [];
    if (this.formfields.length > 0) {
      for (const field in this.formfields) {
        this.addField(this.formfields[field]);
      }
    }
  }
  formValidationCheck = (): void => {
    if (this.chatForm.invalid) {
      this.ref.detectChanges();
    } else {
      this.ref.detectChanges();
    }
  };

  /**
   * Add dynamic form fields to array
   * @param data
   */
  addField(data: FormField): void {
    if (!this.checkDuplicates(data)) {
      this.addedFields.push(data);
      (this.chatForm.get('fields') as FormArray).push(
        this.fb.group(
          {
            fieldLabel: data.fieldLabel,
            fieldId: data.fieldId,
            fieldValue: data.fieldValue,
            fieldName: data.fieldName,
            fieldRequired: data.fieldRequired,
          },
          []
        )
      );
      sessionStorage.setItem('formfields', JSON.stringify(this.chatForm.get('fields').value));
    }
  }

  /**
   * Check for duplicate dynamic form fields
   *
   * @param {FormField} data
   * @returns {boolean}
   */
  checkDuplicates(data: FormField): boolean {
    if (this.addedFields && this.addedFields.length > 0) {
      for (const field of this.addedFields) {
        if (
          data.fieldName.trim() === field.fieldName.trim() ||
          data.fieldId === field.fieldId ||
          data.fieldLabel.trim() === field.fieldLabel.trim()
        ) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Angular ngOnDestroy
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.store.dispatch(doNotLoadDataIsFalse());
  }

  /**
   * Chat Form submission.
   *
   * Sets sessionStorage values, adds them to Genesys chat userData, and then notifies Genesys code to start chat
   *
   */
  onSubmit() {
    sessionStorage.firstname = this.chatForm.get('firstName').value;
    sessionStorage.lastname = this.chatForm.get('lastName').value;
    sessionStorage.subject = this.chatForm.get('subject').value;
    sessionStorage.email = this.chatForm.get('email').value;
    sessionStorage.formfields = JSON.stringify(this.chatForm.get('fields').value);
    if (sessionStorage.fields) {
      this.formfields = this.chatForm.get('fields').value;
      this.formfields.forEach((element: FormField) => {
        if (element) {
          const event = new CustomEvent('AddUserData', { detail: { [element['fieldId']]: element['fieldValue'] } });
          window.dispatchEvent(event);
        }
      });
    }

    const event = new CustomEvent('ValidChatFormSubmitted');

    window.dispatchEvent(event);
  }

  /**
   * If sessionStorage field exists, return value; otherwise return zero-length string
   * @param key
   * @returns
   */
  storageValOrEmpty(key) {
    return sessionStorage[key] ? sessionStorage[key] : '';
  }

  /**
   * Get user Browser version and add to Genesys Chat userData
   */
  getBrowserVersion() {
    const parser = new UAParser();
    const result = parser.getResult();
    const browserVersion = `${result.browser.name} ${result.browser.version}`;
    const e = new CustomEvent('AddUserData', { detail: { CHAT_browserVersion: browserVersion } });
    // const x = { name: 'eeData', param: { 17: browserVersion } };
    // window.postMessage(x, '*');

    window.dispatchEvent(e);
  }

  /**
   * Helper method to set session storage values
   *
   * RECOMMEND MOVING TO SHARED COMPONENT OR ELIMINATING
   * @param key
   * @param value
   */
  setValue(key, value) {
    sessionStorage[key] = value;
  }

  /**
   * When all eeData is processed, updates form values to localStorage or zero-length string and gets browser version
   * @param ready
   */
  loadData(ready) {
    if (ready == true) {
      this.chatForm.patchValue({
        firstName: localStorage.firstname ? localStorage.firstname : '',
        lastName: localStorage.lastname ? localStorage.lastname : '',
        email: localStorage.email ? localStorage.email : '',
      });
      this.getBrowserVersion();
    }
  }
  get email() {
    return this.chatForm.get('email');
  }

  get firstName() {
    return this.chatForm.get('firstName');
  }

  get lastName() {
    return this.chatForm.get('lastName');
  }

  get subject() {
    return this.chatForm.get('subject');
  }

  private getChangedProperties(form): any[] {
    const changedProperties = [];

    Object.keys(form.controls).forEach((name) => {
      const currentControl = form.controls[name];

      if (currentControl.dirty) changedProperties.push({ name: name, value: currentControl.value });
    });

    return changedProperties;
  }
}
