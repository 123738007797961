import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromTabs from './tabs.reducer';
import * as fromChat from './chat.reducer';
import * as fromContact from './contact.reducer';
import * as fromGeneral from './general.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';

/**
 * Primary NgRX Store State
 *
 * @export
 * @interface State
 */
export interface State {
  /**
   * Tabs slice of Store
   */
  tabs: fromTabs.TabsState;
  /**
   * Chat slice of Store
   */
  chat: fromChat.ChatState;
  /**
   * Contact slice of Store
   */
  contact: fromContact.ContactState;
  /**
   * General slice of Store
   */
  general: fromGeneral.GeneralState;
}

/**
 * Combined NgRX reducer
 */
export const reducers: ActionReducerMap<State> = {
  tabs: fromTabs.reducer,
  chat: fromChat.reducer,
  contact: fromContact.reducer,
  general: fromGeneral.reducer,
};

/**
 * Configuration for ngrx-store-localstorage Node module to persist Store data in sessionStorage
 * @param reducer
 * @returns
 */
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['tabs', 'chat', 'contact', 'general'], rehydrate: true, storage: sessionStorage })(
    reducer
  );
}

/**
 * Used by ngrx-store-localstorage to persist Store data in sessionStorage
 */
export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

/**
 * NgRX Chat feature selector
 */
export const selectChatState = createFeatureSelector<State, fromChat.ChatState>(fromChat.chatFeatureKey);

/**
 * NgRX Tabs feature selector
 */
export const selectTabsState = createFeatureSelector<State, fromTabs.TabsState>(fromTabs.tabsFeatureKey);

/**
 * NgRX Contact feature selector
 */
export const selectContactState = createFeatureSelector<State, fromContact.ContactState>(fromContact.contactFeatureKey);

/**
 * NgRX General feature selector
 */
export const selectGeneralState = createFeatureSelector<State, fromGeneral.GeneralState>(fromGeneral.generalFeatureKey);
/**
 * EW Open/Closed Status
 */
export const getEWStatus = createSelector(selectTabsState, (s) => s.ewOpen);

/**
 * Current Active Tab
 */
export const getActiveTab = createSelector(selectTabsState, (s) => s.activeTab);

/**
 * DEPRECATED: contactUsUrl no longer used
 */
export const getContactUsUrl = createSelector(selectTabsState, (s) => s.contactUsUrl);

/**
 * FAQ Tab Content URL from Oracle
 */
export const getFaqUrl = createSelector(selectTabsState, (s) => s.faqsUrl);

/**
 * Unread Messages Count
 */
export const getUnreadMsgCount = createSelector(selectChatState, (x) => x.unreadMessageCount);

/**
 * Check if EW is open and on chat tab
 */
export const ewOpenOnChat = createSelector(getEWStatus, getActiveTab, (ewOpen, url) => {
  return ewOpen === true && url == 'chat';
});
