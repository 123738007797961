import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactUsData } from './contact-us-data.component';
import { ContactService } from './contact.service';

@Component({
  selector: 'app-contact-row',
  templateUrl: './dynamic-contact-row.component.html',
  styleUrls: ['./dynamic-contact-row.component.scss'],
})
export class DynamicContactRowComponent {
  @Input() data: any;
  rows$: Observable<ContactUsData[]>;
  initCallbackForm;

  constructor(private cs: ContactService) {
    
    this.rows$ = cs.contactUsArray$;
    this.initCallbackForm = cs.initCallbackForm;
    
  }

  checkSkillName(skillName) {
    let skill = skillName.trim().replaceAll(' ','_');
    if (JSON.parse(sessionStorage.getItem(skill)) === null ? false : JSON.parse(sessionStorage.getItem(skill))) {
    return true;
    }
    return false;
  }

}