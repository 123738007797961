import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '../store/reducers';
import * as fromContactActions from '../store/actions/contact.actions';
import { Store } from '@ngrx/store';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-standalone',
  templateUrl: './standalone.component.html',
  styleUrls: ['./standalone.component.scss'],
})
export class StandaloneComponent implements OnInit, OnDestroy {
  dataObject: any;

  /**
   * Gets component appId value and uses to load endpoint and determine dynamic form fields
   *
   * @private
   * @type {*}
   */
  private appIdSub: any;

  /**
   * Subscribes to query string parameters, processes them, and posts eeData messages to add parameters to Chat userdata
   *
   * @private
   * @type {*}
   */
  private queryParamsSub: any;
  dataSubscription: Subscription;
  chatStarted: string;

  /**
   *Creates an instance of StandaloneComponent.
   * @param {ActivatedRoute} route
   * @param {Store<State>} store
   */
  constructor(private route: ActivatedRoute, private store: Store<State>) {
    this.dataSubscription = interval(1000).subscribe((x) => {
      this.chatStarted = sessionStorage.getItem('started');
      if (this.chatStarted == 'true') {
        this.postMessageData();
        this.dataSubscription.unsubscribe();
      }
    });
  }

  /**
   * Angular ngOnInit
   */
  ngOnInit(): void {
    window.postMessage({ name: 'ewMaximized' }, '*');
    this.dataObject = {};
    this.appIdSub = this.route.params.subscribe((params) => {
      this.c2e_loadEndpoint(params['appId']);
      this.addFormFields(params['appId']);
    });

    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      let count = 1;
      for (const param in params) {
        const changedurl = params[param].replaceAll('/25/', ',');
        this.dataObject[param] = changedurl.replaceAll('/20/', ' ');
        if (count != Object.keys(params).length) {
          count++;
        }
      }
    });
  }

  /**
   * Triggered by {@link #queryParamsSub|queryParamsSub} to add parsed query string parameters to Chat userData
   */
  postMessageData(): void {
    for (const param in this.dataObject) {
      const x = this.c2e_generateEEParam(param, this.dataObject[param]);
      window.postMessage(x, '*');
    }
  }

  /**
   * Called by {@link #postMessageData|postMessageData()} to put query string parameters in to eeData message shape
   *
   * @param key
   * @param value
   * @returns
   */
  c2e_generateEEParam = (key, value) => {
    let x;
    x = { name: 'eeData', param: {} };
    x.param[key] = value;
    return x;
  };

  /**
   * Triggers Genesys code to load appropriate Chat endpoint
   */
  c2e_loadEndpoint = (lob: string): void => {
    window.postMessage({ c2e_lob: lob }, '*');
  };

  /**
   * Adds additional form fields to based on appId parameter passed
   *
   * @param lob
   * @returns
   */
  addFormFields = (lob: string): void => {
    switch (lob) {
      case 'ebc_sp':
        this.addSingleFormField('CHAT_policyIds', 'Policy Number', 'policyNumber');
        this.addSingleFormField('CHAT_errorMsg', 'Error Message', 'errorMessage');
        break;
      default:
        return;
    }
  };

  /**
   * Adds individual form fields
   *
   * @param fieldId
   * @param fieldLabel
   * @param fieldName
   */
  addSingleFormField = (fieldId: string, fieldLabel: string, fieldName: string): void => {
    const x = { name: 'addNewFormField', fieldId: fieldId, fieldLabel: fieldLabel, fieldName: fieldName };
    window.postMessage(x, '*');
  };

  /**
   * Unsubscribes from various streams
   */
  ngOnDestroy(): void {
    this.appIdSub.unsubscribe();
    this.queryParamsSub.unsubscribe();
  }
}
