import { AfterViewInit, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { takeUntil } from 'rxjs/operators';
import * as fromActions from '../../store/actions/contact.actions';
import * as fromSelectors from '../store/selectors/contact.selectors';

import { QuestionBase } from './cbform-questions-base';
import { ContactService } from '../contact.service';

@Component({
  selector: 'app-question',
  templateUrl: './cbform-questions.component.html',
  styleUrls: ['../contact-callback.component.scss'],
})
export class DynamicFormQuestionComponent {
  constructor(private contactService: ContactService, private store: Store<State>) {}
  @Input() question!: QuestionBase<string>;
  @Input() form!: FormGroup;
  get isValid(): boolean {
    return this.form.controls[this.question.key].valid;
  }
  get isTouched(): boolean {
    return this.form.controls[this.question.key].touched;
  }

  validateDirectToCallback() {
    if (sessionStorage.getItem('c2e_callBackVQ') != undefined) {
      if (
        document.getElementById('callbackNumber').classList.contains('ng-valid') &&
        (document.getElementById('subject') as HTMLInputElement).value != null
      ) {
        (document.getElementById('c2e-submit') as HTMLButtonElement).disabled = false;
        document.getElementById('subject').classList.remove('ng-pristine', 'ng-untouched', 'ng-invalid');
        document.getElementById('subject').classList.add('ng-valid', 'ng-touched', 'ng-dirty');
      }else {
        (document.getElementById('c2e-submit') as HTMLButtonElement).disabled = true;
      }
    }
  }
}
