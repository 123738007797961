/**
 * ChatForm interface
 *
 * @export
 * @interface ChatForm
 */
export interface ChatForm {
  /**
   * Chat Form subject
   * @type {string}
   */
  subject?: string;

  /**
   * Chat Form first name
   * @type {string}
   */
  firstName?: string;

  /**
   * Chat Form last name
   * @type {string}
   */
  lastName?: string;

  /**
   * Chat Form email address
   * @type {string}
   */
  email?: string;
}

/**
 * FormField class
 *
 * @export
 * @class FormField
 */
export class FormField {
  /**
   * Creates an instance of FormField.
   * @param {(number | string)} fieldId
   * @param {string} fieldLabel
   * @param {string} fieldValue
   * @param {string} fieldName
   * @memberof FormField
   */
  constructor(
    public fieldId: number | string,
    public fieldLabel: string,
    public fieldValue: string,
    public fieldName: string,
    public fieldRequired:boolean
  ) {}
}
