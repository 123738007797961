export class QuestionBase<T> {
  value: T | undefined;
  key: string;
  label: string;
  required: boolean;
  validator: string;
  errorMessage: string;
  dropdownHasDefault: boolean;
  order: number;
  controlType: string;
  type: string;
  conditionalProperty: string;
  options: { key: string; value: string }[];

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      validator?: string;
      errorMessage?: string;
      dropdownHasDefault?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      conditionalProperty?: string;
      options?: { key: string; value: string }[];
    } = {}
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.validator = options.validator || '';
    this.errorMessage = options.errorMessage || '';
    this.dropdownHasDefault = options.dropdownHasDefault || false;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.conditionalProperty = options.conditionalProperty || '';
    this.options = options.options || [];
  }
}
